@import '../../styles/vars/vars';
@import '../../styles/common/inner-container';

.root {
  border-bottom: 1px solid $color-line-medium;
  & ul {
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
  }
  & a {
    text-decoration: none;
  }
  @media print {
    display: none;
  }
}

.modal {
  & strong {
    white-space: nowrap;
  }
}

.buttons {
  composes: inner-container;
  & li {
    &:first-child {
      border-right: 1px solid $color-line-medium;
    }
    @media $(bp-medium-up) {
      border-left: 1px solid $color-line-medium;
      &:first-child {
        border-right: 0;
      }

      &:last-child {
        border-right: 1px solid $color-line-medium;
      }
    }
  }
}

.list {
  margin-bottom: $space;
}

.downloadButtonWrap {
  display: flex;
  justify-content: space-around;
  padding: 1.5em 0;

  @media $(bp-small-only) {
    flex-direction: column;
  }
}

.xeroImage {
  width: 6em;

  @media $(bp-small-only) {
    width: 3em !important;
  }
}
