@import '../../../styles/vars/sizing.css';
@import '../../../styles/vars/colors.css';
@import '../../../styles/vars/breakpoints.css';

.switch {
  margin-left: auto;
  position: relative;
  display: inline-block;
  width: 51px;
  height: 31px;

  & .input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  & .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-line-medium;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;

    &:before {
      position: absolute;
      content: '';
      height: 27px;
      width: 27px;
      left: 2px;
      bottom: 2px;
      background-color: $color-white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  & .input:checked + .slider {
    background-color: $color-action-2;
  }

  & .input:focus + .slider {
    box-shadow: 0 0 1px $color-action-2;
  }

  & .input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
}
