@import '../../styles/vars/typography';
@import '../../styles/vars/colors';
@import '../../styles/vars/sizing';

.details {
  display: flex;
  color: $color-text-medium;
  margin-bottom: $space;

  & li {
    padding-right: 0.5rem;

    &:after {
      content: '|';
      display: inline-block;
      margin-left: 0.5rem;
    }

    &:last-child {
      padding-right: 0;

      &:after {
        display: none;
      }
    }
  }
}
