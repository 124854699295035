@import '../../styles/vars/colors';
@import '../../styles/vars/sizing';

.root {
  height: 100%;
  overflow: scroll;
  background: $color-bg-2;
}

.inner {
  display: flex;
  flex-direction: column;
}

.header {
  background: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 1rem;

  & h3 {
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1.1rem;
    color: $color-text-dark;
  }

  & h4 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    color: $color-text-dark;
    font-size: 1rem;
    font-weight: bold;
  }

  & time {
    display: block;
    margin-bottom: 1rem;
    color: $color-text-light;
  }

  & :global(.Select) {
    width: 100%;
  }
}

.logout {
  margin: $space 0 0 0;
  align-self: center;
}

.menu {
  list-style: none;
  margin: 0;
  background: $color-bg-2;
  flex: 1;

  & li:first-child > a {
    border-top: 0;
  }

  & a {
    display: block;
    padding: 1rem;
    border-top: 1px solid #636876;
    position: relative;
    padding-left: 3rem;
    border-left: 5px solid transparent;
    font-weight: normal;
    color: white;
    text-decoration: none;
  }

  & .mainLink {
    text-transform: uppercase;
    font-weight: bold;
  }

  & svg {
    height: 1rem;
    width: 1rem;
    fill: white;
    position: absolute;
    left: 1rem;
  }

  & .active {
    background: $color-bg-2-dark;
    border-left-color: $color-action-1;
  }
}

.prefs {
  & svg {
    left: auto;
    right: 1rem;
    transform: rotate(90deg);
    transition: 0.2s;
  }
}

.prefsOpen {
  & svg {
    transform: rotate(270deg);
  }
}

.subMenu {
  & a {
    background: white;
    padding-left: 4rem;
    border-top: 1px solid $color-line-light;
    color: $color-text-medium;

    & svg {
      fill: $color-text-light;
      left: auto;
      right: 1rem;
    }

    &.active {
      background: white;
    }
  }
}
