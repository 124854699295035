@import '../../../styles/vars/sizing';
@import '../../../styles/vars/breakpoints';

.root {
  z-index: 100;
  position: relative;
  display: none;

  @media $(bp-large-up) {
    display: block;
  }
}

.inner {
  max-width: $width-inner;
  padding-top: calc($space * 2);
  margin: 0 auto;
}

.row {
  display: flex;
  margin-bottom: calc($space * 2);

  &:last-child {
    margin-bottom: 0;
  }
}

.col,
.wideCol {
  flex: 0 0 25%;
  width: 25%;
  padding: 0 $space;

  &:last-child {
    margin-right: 0;
  }
}

.wideCol {
  flex: 0 0 40%;
  width: 40%;
}

.services {
  padding-top: 0;
  background-color: white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  max-width: 32.5rem;
  position: relative;
  left: 3rem;

  & .col {
    flex: 1;
    width: 50%;
  }
}

.services.singleWidth {
  & .col {
    flex: 1;
    width: 99%;
  }
}

.services.extraWidth {
  padding-top: 2rem;
  max-width: 48.5rem;
  & .col {
    flex: 1;
    width: 33%;
  }
}
