@import '../../styles/vars/vars';

.formWrap {
  display: flex;
  justify-content: center;
}

.text {
  margin-bottom: 3rem;
}
