@import '../../styles/vars/sizing';
@import '../../styles/vars/breakpoints';

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: $space 0;

  &.respond {
    @media $(bp-medium-up) {
      flex-direction: row;
    }
  }
}

.col {
  width: 100%;
  margin-right: 0;
  margin-bottom: $space;

  &.respond {
    @media $(bp-medium-up) {
      margin-right: calc($space * 2);
      width: auto;
      margin-bottom: 0;
    }
  }

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
}
