@import '../../../styles/vars/colors';
@import '../../../styles/vars/sizing';
@import '../../../styles/vars/typography';
@import '../../../styles/common/inner-container';

.inner {
  composes: inner-container;
  padding-top: calc($space * 2);
}

.spaceBelow {
  padding-bottom: 4rem;
}

.label {
  font-family: $font-family-functional;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: $color-text-light;
  border-bottom: 1px solid $color-line-light;
  padding-bottom: 0.5rem;
}
