@import '../../../styles/vars/colors';
@import '../../../styles/vars/sizing';

.status {
  margin-bottom: calc($space * 3);
}

.button {
  display: flex;
  justify-content: center;
}

.mb10 {
  margin-bottom: 10px;
}
