@import '../../styles/vars/colors';
@import '../../styles/vars/sizing';

.subject {
  margin-bottom: calc($space * 2);
}
.from {
  display: block;
}

.date {
  display: block;
  margin-bottom: calc($space * 2);
  color: $color-text-light;
}

.reply {
  margin-top: calc($space * 2);
}

.message {
  border-bottom: 1px solid $color-line-light;
  padding-bottom: $space;
  margin-bottom: calc($space * 2);
}
