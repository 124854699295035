@import '../../../styles/vars/vars';

.root {
  display: flex;
  width: 100%;

  @media $(bp-small-only) {
    display: none;
  }

  & input {
    margin-bottom: 0;
  }
}

.col,
.largeCol {
  @media $(bp-medium-up) {
    margin-right: 1.2rem;
  }
}

.col {
  width: 20%;
  display: none;

  @media $(bp-large-up) {
    display: block;
  }
}

.largeCol {
  min-width: 18em;

  @media $(bp-large-up) {
    width: 40%;
  }
}

.dates {
  position: relative;

  & input {
    cursor: pointer;
  }
}

.clearDates {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 1.5rem;
  text-decoration: none;
  color: $color-text-medium;
  height: 100%;
  display: flex;
  align-items: center;
  width: 1.8rem;
  text-align: center;

  &:hover {
    color: black;
  }
}
