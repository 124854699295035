@import '../../../styles/vars/colors';
@import '../../../styles/vars/sizing';
@import '../../../styles/vars/decoration';

.root {
  background: white;
  padding: $space;
  margin-bottom: $space;
  display: flex;
}

.actions {
  margin-top: auto;
}

.inputs {
  display: flex;
}

.calendars {
  margin-right: calc($space * 2);
}

.controls {
  display: flex;
  flex-direction: column;
}

.inputActive {
  outline: $focus-global;
}

.input {
  max-width: 10rem;
  margin-right: 1rem;
}

input[type='text'],
input[type='date'],
textarea {
  width: 100%;
  box-shadow: 0 3px #f0f0f0 inset;
  padding: 0 1rem;
  height: 40px;

  border: 1px solid #cccccc;
}
