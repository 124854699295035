@import '../../../styles/vars/colors';
@import '../../../styles/vars/sizing';

.root {
  margin-bottom: $space;
}

.item {
  display: flex;
  margin-bottom: calc($space/2);
  color: $color-text-light;

  &:last-child {
    margin-bottom: 0;
  }
}

.icon {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 1.2rem;
  background: $color-text-light;
  display: flex;
  padding: 0.35rem;
  margin-right: 0.5rem;

  & svg {
    fill: white;
  }
}

.valid {
  color: $color-action-1;
  & .icon {
    padding: 0.2rem;
    background: $color-action-1;
  }
}
