@import '../vars/colors';

$stripey-height: 1rem;

.stripey-bottom {
  position: relative;
  padding-bottom: $stripey-height;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: $stripey-height;
    background: svg-load('../../assets/svg/stripes.svg', stroke: $color-text-light);
  }
}
