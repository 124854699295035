@import '../../styles/vars/sizing';

.accounts {
  margin-bottom: $space;
}

.showAll {
  display: flex;
  justify-content: center;
}

.accountLink {
  text-decoration: none;
}
