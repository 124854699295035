@import '../../../styles/vars/vars';

.root {
  background: $color-bg-1;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 5px;
  width: 7rem;

  @media $(bp-large-up) {
    height: 3rem;
    bottom: auto;
    border: 1px solid $color-bg-1-dark;
    top: 5px;
    border-top: none;
    width: auto;
    right: $space;
  }
}

.secondaryItem {
  display: none;

  @media $(bp-large-up) {
    display: block;
  }
}

.dotContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  &:after {
    position: absolute;
    display: none;
    content: '';
    border-radius: 2rem;
    width: 0.5rem;
    height: 0.5rem;
    background: $color-action-1;
    top: 0.5rem;
    right: 0.5rem;
  }
  &:before {
    position: absolute;
    display: none;
    width: 100%;
    height: 2px;
    left: 0;
    content: '';
    background: $color-action-1;
    bottom: -1px;
  }
  @media $(bp-large-down) {
    &:before {
      width: calc(100% + 4px);
      bottom: -5px;
    }
  }
}

.showDot {
  &:after {
    display: block;
  }
  &:before {
    display: block;
  }
}

.secondaryItem.noBorder {
  border-left: none;
}

.ourServices {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: calc(100% + 5px);
    background: $color-bg-1-dark;
    left: 0;
    top: -5px;
  }
}

.menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  font-size: 0.8rem;
  text-transform: none;

  & a {
    text-decoration: none;
    color: $color-text-dark;
    padding: 0 1rem;
    min-width: 1.2rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
  }

  & .logout {
    color: $color-action-2;
    font-weight: bold;
    text-transform: uppercase;
  }

  & li {
    height: 100%;
    width: auto;
    border-left: 1px solid $color-bg-1-dark;
  }

  /* This class creates the full height items where
    border touches the top*/
  & .end {
    height: calc(3rem + 5px);
    margin-top: -4px;
    @media $(bp-large-down) {
      border-left: none;
    }
  }

  & svg {
    fill: $color-bg-1-darker;
    height: 1rem;
    width: 1rem;
  }
}
