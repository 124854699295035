@import '../../../styles/vars/sizing';
@import '../../../styles/vars/breakpoints';

.root {
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  margin-bottom: $space;

  & .field {
    margin-right: calc($space/2);
    width: 5rem;
    position: relative;
    display: flex;
    align-items: center;

    &:last-child {
      &:after {
        visibility: hidden;
      }
    }

    &:after {
      content: '-';
      margin-left: calc($space/2);
    }
  }

  & input {
    text-align: center;
    margin: 0;
  }
}
