@import '../../../styles/vars/sizing';
@import '../../../styles/vars/colors';
@import '../../../styles/vars/breakpoints';

.actions {
  margin-top: calc($space * 3);
}

.terms {
  @media $(bp-large-up) {
    height: 20rem;
    overflow: scroll;
    overflow-x: hidden;
    margin-bottom: $space;
    padding: calc($space/2);
    border: 1px solid $color-text-light;
  }
}
