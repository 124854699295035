@keyframes fade {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.loading-empty {
  background: $color-bg-1 !important;
  display: inline-block;
  color: $color-bg-1 !important;
  animation: fade 1s alternate infinite;
}
