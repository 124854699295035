@import '../../styles/vars/vars';

.root {
  display: flex;
  flex-direction: column;
  @media $bp-large-up {
    flex-direction: row;
  }
}

.sidebar {
  background: $color-grey-sidebar;
  border-left: none;
  border-top: solid 1px $color-text-dark;
  padding: 2rem;
  @media $bp-large-up {
    width: 30%;
    border-top: none;
    border-left: solid 1px $color-text-dark;
    padding: 5rem 0 0 2rem;
  }
}

.title {
  font-weight: 600;
}

.link {
  width: 100%;
  display: block;
  line-height: 2rem;
}
