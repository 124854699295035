@import '../../styles/vars/sizing';
@import '../../styles/vars/breakpoints';

.inner {
  margin-bottom: $space;
}

.form {
  padding-bottom: $space;

  @media $(bp-medium-up) {
    padding-bottom: 0;
    display: flex;
  }
}

.col,
.tinyCol {
  @media $(bp-medium-up) {
    flex: 1;
    margin-right: $space;
  }

  @media $(bp-large-up) {
    flex: 0 1 25%;
    width: 25%;
  }

  &:last-child {
    margin-right: 0;
  }
}

.tinyCol {
  flex: 0.4;

  @media $(bp-large-up) {
    display: none;
  }
}
