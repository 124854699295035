@import '../../styles/vars/colors';
@import '../../styles/vars/sizing';
@import '../../styles/vars/breakpoints';

.root {
}

.top {
  display: flex;
  flex-direction: column;

  @media $(bp-large-up) {
    flex-direction: row;
  }
}

.subject {
  font-size: 1rem;
  margin-bottom: calc($space/2);
}

.excerpt {
  color: $color-text-light;
}

.date {
  color: $color-text-light;
  order: -1;
  margin-bottom: calc($space/2);

  @media $(bp-large-up) {
    order: 1;
    margin-left: auto;
  }
}

.item {
  border-top: 1px solid $color-line-medium;

  &:last-child {
    border-bottom: 1px solid $color-line-medium;
  }
}

.link {
  display: block;
  color: $color-text-dark;
  text-decoration: none;
  padding: $space;
  position: relative;
}

.unread {
  padding-left: 1.5rem;

  &:before {
    display: block;
    content: '';
    height: 0.7rem;
    width: 0.7rem;
    background: $color-action-1;
    position: absolute;
    left: 0;
    margin-top: 0.2rem;
    border-radius: 1rem;
  }
}

.results {
  margin-top: calc($space * 2);
}
