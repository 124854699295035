@import '../../styles/vars/vars';

.root {
  padding: calc($space * 4);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;

  @media $(bp-small-only) {
    padding: calc($space * 2);
  }
}

.heading {
  font-family: $font-family-functional;
  margin-bottom: calc($space * 1.5);
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.viewButton {
  margin-right: $space;
}
