@import '../../styles/vars/colors';
@import '../../styles/vars/sizing';
@import '../../styles/vars/breakpoints';

.anchor {
  display: block;
  color: $color-text-dark;
  text-decoration: none;
}

.root {
  border: 1px solid $color-line-medium;
  position: relative;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  padding: calc($space/2);

  @media $(bp-medium-up) {
    display: flex;
    align-items: center;
    padding: $space calc($space * 2);
  }

  @media $(bp-small-only) {
    flex-direction: column;
  }
}

.actionButton {
  margin: 0 $space 0 0;
}

.editing {
  border: 2px solid $color-action-1;
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.hasCheckbox {
  padding-left: 4rem;
}

.checkbox {
  position: absolute;
  left: 1rem;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;

  & label,
  & [class*='option'] {
    margin: 0;
  }
}

.titleCol {
  flex: 0 0 40%;
  font-weight: bold;

  @media $(bp-medium-up) {
    margin-right: $space;
  }

  & div {
    margin-bottom: calc($space/2);

    @media $(bp-medium-up) {
      margin-bottom: 0;
    }
  }
}

.detailsCol {
  color: $color-text-light;
}

.extra {
  display: block;
  color: $color-text-light;
  font-size: 0.9rem;
}

.textCol {
  white-space: nowrap;

  @media $(bp-medium-up) {
    text-align: right;
  }

  & span {
    display: block;
  }
}

.action,
.actions,
.textCol {
  @media $(bp-medium-up) {
    margin-left: auto;
  }
}

.action,
.actions {
  display: block;
  border-top: 1px solid $color-line-light;
  width: 100%;
  padding-top: calc($space/2);
  margin-top: calc($space/2);

  @media $(bp-medium-up) {
    padding: 0;
    width: auto;
    border: 0;
  }
}

.actions {
  display: flex;
}

.save {
  margin-right: $space;
  color: $color-action-1;
}

.cancel {
  color: $color-text-light;
}

.boxButton {
  margin-left: $space;
  background: none;
  border: solid 1px $color-line-medium;
  border-radius: 2px;
  width: 1.9em;
  height: 1.9em;
  display: flex;
  padding: 0.4em;
  cursor: pointer;
  & svg {
    fill: $color-text-light;
  }
}
