@import '../../styles/vars/vars';

$color-bg-tip: $color-bg-2-dark;
$size-triangle: 8px;

.root {
  position: relative;
  color: white;
}

.inline {
  display: inline-block;
  margin: 0 0.5em;
}

.tip {
  display: none;
  position: absolute;
  width: 10rem;
  font-size: 0.8rem;
  text-align: center;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: calc($size-triangle + 3px);
  background: $color-bg-tip;
  padding: 0.5rem;
  z-index: 2;

  &:before {
    display: block;
    position: absolute;
    top: calc(-$size-triangle * 2);
    content: '';
    width: calc($size-triangle * 2);
    height: calc($size-triangle * 2);
    left: 50%;
    margin-left: -$size-triangle;
    border: $size-triangle solid transparent;
    border-bottom-color: $color-bg-tip;
  }

  &.tipLeft {
    margin-left: calc($space/2 * -1);

    &:before {
      left: 1.25rem;
    }
  }

  &.tipRight {
    right: 0;
    margin-left: 0;
    transform: none;
    margin-right: -4px;

    &:before {
      left: auto;
      right: 1.25rem;
    }
  }
}

.isShowing {
  display: block;
}
