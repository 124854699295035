@import '../../styles/vars/vars';

.root {
  margin: calc($space * 2) 0;

  @media print {
    display: none;
  }
}

.status {
  margin: calc($space * 2) 0;
}

.authMessage {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin-top: $space;
  color: $color-success;
}

.timeOutMessage {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin-top: $space;
  color: $color-error;
}

.secondaryMessage {
  font-size: 1rem;
  text-align: center;
  margin-top: $space;
}

.buttonWrap {
  @media $(bp-small-only) {
    margin: 0 $space;
  }
}
