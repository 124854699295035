@import '../../styles/vars/colors';
@import '../../styles/vars/breakpoints';

.list {
  display: none;

  @media $(bp-medium-up) {
    display: flex;
  }

  & li {
    margin-right: 1.5rem;

    &:last-child {
      margin: 0;
    }
  }

  & a {
    color: $color-text-medium;
    text-decoration: underline;
  }

  & .active {
    color: $color-action-2;
    text-decoration: none;
  }

  & .disabled {
    color: $color-text-light;
    text-decoration: none;
  }
}

.listMob {
  display: flex;

  @media $(bp-medium-up) {
    display: none;
  }

  & li {
    border: 1px solid $color-line-medium;
    border-right: 0;

    &:last-child {
      border-right: 1px solid $color-line-medium;
    }
  }

  & a {
    display: block;
    height: 3rem;
    width: 3rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & svg {
    fill: $color-text-medium;
  }

  & .currentPage {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-text-medium;
  }
}
