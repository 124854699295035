@import '../../styles/common/inner-container';
@import '../../styles/vars/sizing';

.inner {
  composes: inner-container;
}

.sticky {
  z-index: 1;
  background: white;
}

.mockTableHeader {
  composes: inner-container;
  background: white;
  display: none;

  @media $(bp-medium-up) {
    display: block;
  }
}
