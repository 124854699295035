@import '../../styles/vars/sizing';

.root {
  display: flex;
  align-items: center;
  margin-bottom: calc($space * 2);
  min-height: 3rem;
}

.wrap {
  word-break: break-all;
  white-space: pre-line;
}

.header {
  margin-bottom: 0;
}

.action {
  margin-left: auto;
  margin-bottom: 0;
}
