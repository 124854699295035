@import '../../styles/vars/vars';
@import '../../styles/common/inner-container';

.statsBg {
  background: $color-bg-1;
}

.stats {
  composes: inner-container;
  display: flex;
  justify-content: flex-start;
  padding: calc($space * 2) $space;
}

.stat {
  flex: auto;
  display: flex;
  flex-direction: column;
}

.statLabel {
  font-size: 1em;
  margin-bottom: 0.5em;
}

.statNumber {
  font-size: 1.5em;
  font-weight: 600;
}
