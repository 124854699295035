@import '../../styles/vars/sizing';
@import '../../styles/vars/colors';
@import '../../styles/vars/breakpoints';

.root {
  border-top: 1px solid $color-line-light;
  color: $color-text-light;
  padding-bottom: calc($space * 3);

  @media $(bp-medium-up) {
    padding: $space 0;
  }

  & p {
    font-size: 0.8rem;
    line-height: 1rem;
  }

  @media print {
    display: none;
  }
}

.links {
  font-size: 0.8rem;
  display: flex;
  line-height: 1rem;
  justify-content: center;
  margin-bottom: calc($space * 2);

  @media $(bp-medium-up) {
    margin-bottom: 0;
  }

  & a {
    color: $color-text-light;
    text-decoration: none;
  }

  & li {
    margin-right: $space;

    &:last-child {
      margin-right: 0;
    }
  }
}

.inner {
  max-width: $width-inner;
  margin: 0 auto;
  padding: $space;
  align-items: center;

  @media $(bp-medium-up) {
    display: flex;
    align-items: center;
  }
}

p.name {
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: $space;
  margin-top: calc($space * 2);

  @media $(bp-medium-up) {
    text-align: left;
    margin: 0 calc($space * 3) 0 0;
    font-size: 0.8rem;
  }
}

.icon {
  width: 2rem;
  height: 2rem;
  margin: 0 auto;

  & svg {
    fill: $color-text-light;
  }

  @media $(bp-medium-up) {
    margin: 0 calc($space * 2) 0 0;
    order: -1;
  }
}

.modalMessage {
  padding: 1em;
}

.version {
  font-size: 0.8rem;
}
