@import '../../../styles/vars/vars';

.root {
  position: relative;
  display: inline-block;
  margin-bottom: $space;

  & input[type='text'] {
    margin-bottom: 0;
  }
  @media $(bp-large-down) {
    max-width: 7.2em;
  }
  @media $(bp-small-only) {
    max-width: 5.5em;

    & input[type='text'] {
      padding: 0 0.6rem;
      font-size: 0.8em;
    }
  }
}

.icon {
  position: absolute;
  top: 0;
  right: 0.5rem;
  width: 1.5rem;
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;

  & svg {
    fill: $color-text-light;
  }
}
