@import '../../styles/vars/sizing';

.ref {
  display: block;
  margin-bottom: calc($space/2);
}

.successDiv {
  background: #41beae;
  height: 75px;
  padding: 15px 10px;
  display: flex;
  margin-bottom: 20px;
}

.iconDiv {
  width: 14%;
  display: inline-block;
  text-align: center;
  margin-top: 6px;
}

.successIcon {
  width: 38px;
  height: 38px;
}

.successTextDiv {
  width: 80%;
  display: inline-block;

  .successHeader {
    font-family: Arial;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    color: white;
  }
  .successSubHeader {
    font-family: Arial;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.03px;
    text-align: left;
    color: white;
  }
}
