@import '../../../styles/vars/sizing';
@import '../../../styles/vars/colors';

.root {
  position: relative;
  margin-bottom: $space;
}

.showHide {
  position: absolute;
  top: 0;
  right: 0;
  color: $color-action-1;
  text-decoration: none;
  text-transform: uppercase;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  width: 4rem;
}

input.input {
  padding-right: 4rem;
  margin-bottom: 0;
}
