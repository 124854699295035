@import '../../styles/vars/vars';

.paymentsView {
  padding-top: calc($space * 3);
}

.darkSection {
  background: $color-bg-1;
  margin-top: calc($space * 2);
  padding: calc($space * 2) 0;
}

.secondaryTitle,
.panelTitle {
  margin-bottom: calc($space * 2);
  font-family: $font-family-functional;
  letter-spacing: 0.5px;
}

.panelTitle {
  text-align: center;
}

.moreOptions {
  display: block;
  text-align: right;
  margin-right: calc($space * 2);
}

.dottedLine {
  border-top: dotted 2px $color-action-2-light;
}
