@import '../../styles/vars/colors';
@import '../../styles/vars/breakpoints';

.modalComponent {
  padding: 0;
}

.modal {
  grid-template-columns: repeat(4, lfr);
  grid-gap: 10px;
  width: auto;

  & .modalIcon {
    width: auto;
    height: 150px;
    padding: 24px 31px 24px 31px;
    background-color: $color-error;

    & .modalSVG {
      margin-top: auto;
      margin-bottom: auto;
      height: 100%;
    }
  }

  & .modalContent {
    padding: 39px 20px 24px 20px;
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    gap: 16px;

    & .modalHeader {
      color: $color-text-dark;
      font-family: 'Arial', serif;
      font-weight: 700;
      font-size: 21px;
      h1 {
        font-family: Arial;
        font-size: 21px;
        font-weight: 700;
        line-height: 24.15px;
        text-align: left;
      }
    }

    & .modalText {
      font-size: 16px;
      font-weight: 400;
      line-height: 19.36px;
      text-align: left;
    }
  }
}

@media $(bp-medium-up) {
  .modal {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 10px;
    width: 520px;

    & .modalIcon {
      width: 176px;
      height: 100%;
      padding: 24px 31px 24px 31px;
      background-color: $color-error;

      & .modalSVG {
        margin-top: auto;
        margin-bottom: auto;
        height: 100%;
      }
    }

    & .modalContent {
      padding: 39px 20px 24px 20px;
      background-color: $color-white;
      display: flex;
      flex-direction: column;
      gap: 16px;

      & .modalHeader {
        color: $color-text-dark;
        font-family: 'Arial', serif;
        font-weight: 700;
        font-size: 21px;
        h1 {
          font-family: Arial;
          font-size: 21px;
          font-weight: 700;
          line-height: 24.15px;
          text-align: left;
        }
      }

      & .modalText {
        font-size: 16px;
        font-weight: 400;
        line-height: 19.36px;
        text-align: left;
      }
    }
  }
}

/* ConfirmationOfPayee component */
.container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
  min-width: auto;
  max-width: 450px;
  height: auto;
  margin: 0 auto;
}

.headingMessage {
  background-color: $color-error;
  padding: 15px;
  margin-bottom: 20px;
  text-align: left;
  color: $color-white;

  & p {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }

  & .svgIcon {
    height: 32px;
    width: 28px;
    margin-right: 10px;
    vertical-align: middle;
    margin-top: -6px;
  }
}

.subHeading {
  & span {
    color: #2d3236;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    display: block;
    margin-bottom: 20px;
  }
}
.subHeadingNotFoundCase {
  & span {
    font-size: 14px;
    line-height: 18px;
  }
}
.accountNotFoundHeader {
  background: #e6ebeb;
  color: black;
}
.listErrors {
  display: block;
  background: #e6ebeb4d;
  padding: 20px;
  margin: 20px auto 0;
}

.actionButton {
  gap: 8px;
  height: 48px;
  padding: 16px 26px;
  top: 334px;
  min-width: 300px;
  max-width: 341px;
  text-align: center;
  outline: none;
  position: inherit;
  font-family: Arial, serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.1px;
  letter-spacing: 1px;
  background: #bfa77c;
  display: block;
  color: #ffffff;
  border: none;
  margin: 14px auto 0;
  cursor: pointer;
  border-radius: 4px;
}

.cancel {
  color: #bfa77c;
  text-decoration: underline;
  background: none;
}

.actionButton2 {
  color: black;
  border: 1px solid black;
  background: #ffffff;
}

.actionButton2:hover {
  background: none;
}

/* ErrorList component */
.errorDiv {
  width: 100%;
}

.errorDiv:last-child .innerErrorDiv {
  border-bottom: none;
  margin-bottom: -15px;
}

.innerErrorDiv {
  border-bottom: 1px solid #ddddde;
  margin-bottom: 15px;
}

.innerErrorList {
  display: block;
  font-family: Arial, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  width: 100%;
  color: #666;
}

.miniIcons {
  height: 15px;
  width: 15px;
  margin-right: 7px;
  vertical-align: middle;
  margin-top: -3px;
}

.innerErrorListBottom {
  margin-left: 20px;
  color: black;
  font-weight: 700;
  margin-bottom: 15px;
}

.continueAlert {
  min-height: 280x;
  align-items: center;
  margin-top: 125px;
  padding-left: 10px;
}

.body {
  min-width: 75%;
  margin-bottom: 40px;
}

.bodyContainer {
  min-width: 350px;
  margin-top: 50px;
  margin-left: 25px;
  margin-right: 25px;
}

.continueHeader {
  font-weight: 400;
  font-size: 21px;
  color: #2d3236;
  padding-bottom: 21px;
}

.cancelButton {
  width: 295px;
}

.continueAnywayButton {
  background: #ffffff;
  border: 1px solid #333;
  color: #333;
  width: 295px;
}
.continueAnywayButton:hover {
  background-color: #ffffff;
}

.noBottom {
  border-bottom: none;
}

.accountDetails {
  .accountDetailsRow {
    border-bottom: 1px solid #e2e2e2;
    padding-top: 15px;
    padding-bottom: 15px;
    .title {
      font-family: Arial;
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
      min-width: 39%;
      display: inline-block;
    }

    .value {
      font-family: Arial;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      display: inline-block;
    }
  }
}

.accountDetailsRow:last-child {
  border: none;
}
