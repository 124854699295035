.genericoverlay {
  position: fixed; /* Stay in place */
  z-index: 99999; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
    padding-top: 50px; /* Location of the box */
  }
}

.genericoverlayheader {
  width: 100%;
  height: 55px;
  background: #f2f1ec;
  color: #313131;
  padding: 3%;
  font-size: 18px;
  font-weight: bold;

  @media only screen and (max-width: 600px) {
    background: white;
    font-weight: normal;
  }
}

/* The Close Button */
.genericoverlayclose {
  float: right;
  font-size: 28px;
  margin-top: -7px;
  color: #bfa77c;
  font-weight: 300;
  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.genericoverlayclose:hover,
.genericoverlayclose:focus {
  color: #bfa77c;
  text-decoration: none;
  cursor: pointer;
}

/* Modal Content */
.genericoverlay-content {
  background-color: #fefefe;
  margin: auto;
  border: 1px solid #888;
  width: 541px;
  min-height: 300px;
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
}

.genericoverlaygoBack {
  display: none;
  @media only screen and (max-width: 600px) {
    display: block;
    margin-left: 12px;
    padding-top: 14px;
    font-size: 12px;
    cursor: pointer;
  }
}

.genericoverlayleft {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.genericoverlayiconClass {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-right: 5px;
}
