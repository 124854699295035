@import '../../styles/vars/sizing';
@import '../../styles/vars/breakpoints';
@import '../../styles/vars/colors';

.root {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;

  &.respond {
    @media $(bp-medium-up) {
      flex-direction: row;
      margin: 0 -$space;
    }

    @media $(bp-large-up) {
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }
}

.col {
  margin-bottom: calc($space/2);
  padding-bottom: calc($space/2);
  border-bottom: 1px solid $color-line-medium;

  &:last-child {
    border: 0;
  }

  &.respond {
    @media $(bp-medium-only) {
      flex: 0 0 50%;
    }

    @media $(bp-medium-up) {
      padding: 0 $space;
      border: 0;
      margin-bottom: $space;
      flex: 1;
    }
  }
}
