@import '../../styles/vars/vars';

.root {
}

.heading {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.group {
  margin-bottom: calc($space * 2);
}

.savedText {
  margin-left: 1em;
}

.marginTop {
  position: relative;
  top: 12px;
}
