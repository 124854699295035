@import '../../styles/vars/colors';
@import '../../styles/vars/sizing';
@import '../../styles/vars/breakpoints';
@import '../../styles/vars/typography';

.root {
  height: $height-header-small;

  @media $(bp-large-up) {
    height: auto;
  }

  background: white;

  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 3px;
    background: $color-bg-1;

    @media $(bp-large-up) {
      height: 6px;
      border-bottom: 1px solid $color-bg-1-dark;
    }
  }

  @media print {
    display: none;
  }
}

.loggedOut {
  &:before {
    display: none;
  }

  display: flex;
  justify-content: center;

  @media $(bp-large-up) {
    display: block;
  }

  & img {
    margin: 0;
  }
}

.logoStacked {
  width: 10rem;
  height: 2rem;
  margin-left: 3.5rem;

  @media $(bp-large-up) {
    display: block;
    margin-left: $space;
    margin-top: 5px;
    width: 12rem;
  }
}

.inner {
  height: 100%;
  padding: 0 $space;
  margin: 0 auto;
  max-width: $width-global;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
}

.top {
  height: $height-header-small;

  @media $(bp-large-up) {
    height: 5rem;
    border-bottom: 1px solid $color-bg-1-dark;
  }

  & .inner {
  }
}

.moduleLeft,
.moduleRight {
  display: none;
  @media $(bp-large-up) {
    display: flex;
    height: 100%;
  }
}

.moduleLeft {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  order: 1;
  width: 100%;
  display: flex;
  justify-content: center;

  @media $(bp-large-up) {
    position: static;
    width: auto;
    order: 0;
    margin-right: auto;
  }
}

.moduleRight {
  @media $(bp-large-up) {
    display: flex;
    align-items: flex-end;
    padding: 1rem 0;
    padding: 0 0 0.5rem;
    margin-left: 1rem;
  }

  @media $(bp-extra-large-up) {
    margin-left: 3rem;
  }
}

.selectEntity {
  display: none;
  z-index: 1001;
  width: 20rem;

  @media $(bp-large-up) {
    display: flex;
    width: 15rem;
  }

  @media $(bp-extra-large-up) {
    width: 20rem;
  }
}

.bottom {
  display: none;
  height: 4rem;
  justify-content: space-around;

  & .inner {
    justify-content: space-around;
    height: 0;
    height: 100%;
  }

  @media $(bp-large-up) {
    display: block;
  }
}

.mobMenuButton {
  font-family: $font-family-functional;
  font-size: 0.7rem;
  text-transform: uppercase;
  padding-left: 0.5rem;
  font-weight: bold;
  color: $color-bg-1-darker;
  width: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 3px;
  bottom: 0;
  left: 0;
  background: url('../../assets/svg/thin-chevron.svg');
  background-repeat: no-repeat;
  background-size: auto 120%;
  background-position: center right;

  @media $(bp-large-up) {
    display: none;
  }
}

.active {
  font-weight: bold;
  text-decoration: underline;
}
