@import '../../../styles/vars/sizing';
@import '../../../styles/vars/breakpoints';

.root {
  margin: 0 auto;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.col1 {
  display: flex;
  flex: 0 0 35%;
  align-items: center;
  padding-right: $space;
}

.col2 {
  display: flex;
  flex: 1;
  align-items: center;
}

.hideLabel {
  @media $(bp-large-up) {
    display: none;
  }
}

.label1,
.label2 {
  @media $(bp-large-up) {
    text-align: right;
    margin-bottom: $space;
  }
}

.label1 {
  display: none;

  @media $(bp-large-up) {
    display: block;
    position: absolute;
    width: 20rem;
    left: -20rem;
    top: 0;
    padding-right: $space;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: $space;
  }
}

.label2 {
  @media $(bp-large-up) {
    flex: 0 0 40%;
    padding-right: $space;
  }
}

.input1,
.input2 {
  width: 100%;
}
