@import '../../styles/vars/colors';

.root {
  border-right: 1px solid $color-text-dark;
  padding: 5rem 0;
  height: 100%;
}

.item {
  padding-left: 2rem;
  position: relative;
}

.link {
  text-decoration: none;
}

.linkInner {
  display: block;
  padding: 1rem 0;
  border-bottom: 1px solid transparent;
  color: $color-text-dark;
}

.active {
  &:before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 0.6rem;
    bottom: 0.6rem;
    width: 10px;
    background: $color-action-1;
  }

  & .linkInner {
    border-bottom-color: $color-line-medium;
    color: $color-action-2;
  }
}
