@import '../../styles/vars/sizing';
@import '../../styles/vars/colors';
@import '../../styles/vars/breakpoints';

.root {
  display: flex;
  align-items: stretch;
  flex-direction: column;

  @media $(bp-large-up) {
    flex-direction: row;
  }
}

.sidebar {
  flex: 0 0 18rem;
  width: 18rem;
  display: none;

  @media $(bp-large-up) {
    display: block;
  }
}

.main {
  padding: 0;
  flex: 1;
}
