@import '../../styles/vars/sizing';
@import '../../styles/vars/colors';

.root {
  border: 1px solid $color-line-medium;
  text-align: center;
  padding: calc($space * 2);
}

.buttons {
  display: flex;
  justify-content: center;
}

.col {
  margin-right: $space;

  &:last-child {
    margin-right: 0;
  }
}
