@import '../../styles/vars/sizing';
@import '../../styles/vars/breakpoints';
@import '../../styles/vars/colors';

.root {
  background: white;
  padding: $space;
  margin-bottom: $space;
  color: $color-text-dark;
}
