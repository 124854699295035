@import '../../styles/vars/vars';

.form {
  padding-bottom: $space;

  @media $(bp-medium-up) {
    padding-bottom: 0;
    display: flex;
    flex-wrap: wrap;
  }

  @media $(bp-large-up) {
    flex-wrap: nowrap;
  }
}

.col {
  @media $(bp-medium-up) {
    flex: 1;
    margin-right: $space;
  }

  &:last-child {
    margin-right: 0;
  }
}

.col1 {
  @media $(bp-large-up) {
    flex: 1 1 30%;
  }
}

.col2 {
  @media $(bp-large-up) {
    flex: 1 1 15%;
  }
}

.col3 {
  @media $(bp-large-up) {
    flex: 1 1 15%;
  }
}

.rangeCol {
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 30%;

  & input {
    width: 8em;
  }

  @media $(bp-large-down) {
    flex-basis: 100%;
  }
}

.rangeLabel {
  width: 100%;
}

.rangeTo {
  margin: 0.8em 1em 0;
}

.rangeInput {
  flex: 0 0 10em;
}
