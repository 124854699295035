.root {
  color: inherit;
}

.discList {
  list-style: inside disc;

  & li {
    line-height: 1.8rem;
  }
}
