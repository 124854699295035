@import '../../styles/vars/vars';

.notes {
  margin-top: 2em;
}

.header {
  font-size: 1.5em;
  font-family: $font-family-fancy;
  margin-bottom: 1.5em;
}
