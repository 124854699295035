.root {
  position: absolute;
  margin-top: 1px;
  width: 100%;
  background: white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  display: none;
}

.services {
  background-color: transparent;
  box-shadow: none;
}

.active {
  display: block;
}
