@import '../../styles/vars/vars';

.root {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc($space/2);

  @media $(bp-medium-up) {
    padding: $space;
  }
}

.modal {
  position: relative;
  background: white;
  width: 100%;
  max-height: 100%;
  padding: calc($space/2);
  overflow-y: auto;

  @media $(bp-medium-up) {
    padding: $space;
  }

  @media $(bp-large-up) {
    overflow-y: visible;
    padding: calc($space * 2);
  }

  &:focus {
    outline: none;
  }
}

.noScroll {
  overflow-y: visible;
}

.title {
  padding-right: 2rem;
}

.close {
  display: block;
  position: absolute;
  text-decoration: none;
  background: black;
  border-radius: 10rem;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  line-height: 1.5rem;
  border: 1px solid white;
  top: 0.75rem;
  right: 0.75rem;
  color: white;
  cursor: pointer;

  @media $(bp-large-up) {
    top: -0.75rem;
    right: -0.75rem;
  }

  &:hover {
    color: #ccc;
  }
}

.small {
  max-width: 30rem;
}

.medium {
  max-width: 40rem;
}

.large {
  max-width: 50rem;
}

.actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: calc($space * 2);

  & button {
    margin: 0 calc($space * 2) 0 0;
    &:first-of-type {
      margin-right: $space;
    }
  }

  @media $(bp-small-only) {
    flex-direction: column;
    & button {
      width: 100%;
      margin: 0 0 $space;
      &:first-of-type {
        margin-right: 0;
      }
    }
  }
}

.footer {
  font-size: 0.75em;
  color: $color-text-light;
  display: block;
  margin: 1em 0 -1em;
  line-height: 130%;
}

.nonClickable {
  pointer-events: none;
}
