@import '../../styles/vars/vars';

.root {
  padding: calc($space * 2.5) calc($space * 4);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;

  @media $(bp-small-only) {
    padding: calc($space * 2);
  }
}

.heading {
  font-family: $font-family-functional;
  margin-bottom: calc($space * 1.5);
  text-align: center;
  max-width: calc($space * 37.5);
  font-weight: 500;
  width: 100%;
  line-height: 2.5rem;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.button {
  margin-top: auto;
  color: #bfa77c;
  font-family: Helvetica, Arial, sans-serif;
  padding: 0;
  border: none;
  text-decoration: underline;
  text-transform: none;
  text-align: left;
  font-weight: 500;
}

.darkSection {
  /* background-color: $color-bg-1; */
  border: 1px solid $color-grey-block-border;
}

.infoBlock {
  font-size: 0.9rem;
  @media $(bp-extra-large-up) {
    max-width: 70%;
    margin: auto;
  }
}
