@import '../../styles/vars/sizing';
@import '../../styles/vars/breakpoints';

.root {
  display: block;

  @media $(bp-medium-up) {
    display: flex;
  }
}

.accountSelectCol {
  flex: 0 0 20rem;

  @media $(bp-medium-up) {
    margin-right: $space;
  }
}

.typeSelectCol {
  flex: 0 0 8rem;
  margin-right: $space;
}

.yearSelect {
  margin: 0;
}

.accountSelect {
  @media $(bp-medium-up) {
    margin: 0;
  }
}

.yearSelectCol {
  flex: 0 0 6rem;

  & :global .Select {
    margin: 0;
  }
}
