@import '../../styles/vars/vars';

.root {
  display: flex;
  flex-direction: column;

  @media $(bp-medium-up) {
    flex-direction: row;
  }

  & h3 {
    margin-bottom: 0.5rem;
  }
}

.item {
  flex: 1;

  display: flex;
  flex-direction: column;

  padding: $space 0;
  border-bottom: 1px solid $color-line-medium;

  @media $(bp-medium-up) {
    padding: 0;
    border: 0;
    margin-right: calc($space * 3);

    &:last-child {
      margin-right: 0;
    }
  }
}

.title {
  font-weight: 600;
  font-size: 1.3em;
}

.body {
  margin-bottom: calc(3 * $space);
}

.link,
.button {
  margin-top: auto;
  text-decoration: none;
  color: inherit;
  border: solid 2px;
  border-radius: 3px;
  padding: 12px;
  font-weight: 600;
  font-family: $font-family-functional;
  max-width: 75%;
  text-align: center;
  text-transform: uppercase;
}

.button {
  color: $color-white;
  border-color: $color-action-2;
  background-color: $color-action-2;
}

.border {
  border: 1px solid $color-grey-block-border;
  padding: calc($space * 1.3) $space;
  margin-top: 1rem;
  &:first-child {
    margin-top: 0;
  }
  @media $(bp-medium-up) {
    margin-right: calc($space * 1.8);
    margin-top: 0;
    &:last-child {
      margin-right: 0;
    }
  }

  & .title {
    text-transform: uppercase;
    font-size: 1.1rem;
    letter-spacing: 3px;
    margin-bottom: 1.5rem;
  }

  & .body,
  & .marginTop {
    margin-bottom: calc($space * 1.8);
    line-height: 1.8rem;
  }

  & .marginTop {
    top: 1.2rem;
    position: relative;
  }

  & .link {
    margin-top: auto;
    color: $color-action-2;
    font-family: $font-family-functional;
    padding: 0;
    border: none;
    text-decoration: underline;
    text-transform: none;
    text-align: left;
    font-weight: 500;
  }
}
