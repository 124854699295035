@import '../../styles/vars/vars';

.root {
  border: 1px solid $color-line-medium;
  margin-bottom: calc($space * 2);
}

.header {
  border-bottom: 1px solid $color-line-medium;
  padding: $space;
  display: flex;
}

.headerItem {
  margin-right: calc($space * 2);

  & strong {
    display: inline-block;
    margin-right: calc($space / 2);
  }

  &:last-child {
    margin-right: 0;
    margin-left: auto;
  }
}

.body {
  padding: $space $space 0 $space;
}

.footer {
  padding: 0 $space $space $space;
  display: flex;
  align-items: center;
}

.authorised,
.declined,
.semi {
  font-size: 1.2rem;
  text-transform: uppercase;
}

.authorised {
  color: $color-success;
}

.declined {
  color: $color-error;
}

.semi {
  color: $color-text-light;
  margin-left: calc($space * 2);
}

.pastMessage {
  margin-right: $space;
}

.bulkLink {
  display: block;
  margin: 1em 0 2em $space;
  color: $color-action-1;
}

.nonClickable {
  pointer-events: none;
}
