@import '../../styles/vars/sizing';

.root {
  text-align: center;
  padding: calc($space * 5) 0;
}

.title {
  margin: calc($space * 2) 0 calc($space * 4);
}
