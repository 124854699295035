@import '../../styles/vars/vars';

.root {
  margin-bottom: 2em;
  display: flex;
  @media $(bp-small-only) {
    flex-wrap: wrap;
  }
}

.left {
  flex: auto;
}

.reference {
  white-space: pre-line;
  word-break: break-all;
  margin-top: 1em;
}

.right {
  flex: 0 1 20%;
  @media $(bp-small-only) {
    flex-grow: 1;
  }
}

.status {
  font-size: 1.2em;
  font-weight: 600;
  margin: 0.5em 0;
}

.detail,
.mutedDetail {
  display: block;
  margin-bottom: 0.2em;
  word-break: break-all;
}

.mutedDetail {
  color: $color-text-light;
}
