@import '../../styles/vars/colors';
@import '../../styles/vars/typography';
@import '../../styles/vars/breakpoints';
@import '../../styles/vars/sizing';
@import '../../styles/common/inner-container';

.root {
  border-bottom: 1px solid $color-line-medium;

  @media $(bp-medium-up) {
    padding: $space 0;
  }

  @media print {
    display: none;
  }
}

.inner {
  composes: inner-container;
  align-items: center;
  display: flex;

  @media $(bp-small-only) {
    padding: 0;
  }
}

.title {
  margin: 0 auto 0 0;
  color: $color-action-2;
  display: none;

  @media $(bp-medium-up) {
    display: block;
  }
}

.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media $(bp-medium-up) {
    width: auto;
    flex-direction: row;
    justify-content: flex-end;
  }

  & li {
    border-bottom: 1px solid $color-line-light;

    @media $(bp-medium-up) {
      border-right: 1px solid $color-line-light;
      border-bottom: 0;
    }

    &:last-child {
      border-right: 0;
      border-bottom: 0;

      & .button {
        @media $(bp-medium-up) {
          padding-right: 0;
        }
      }
    }
  }
}

.button {
  display: flex;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  color: $color-text-dark;
  font-weight: bold;
  letter-spacing: $letter-spacing-action;
  padding: $space;

  @media $(bp-medium-up) {
    padding: 0.5rem 1.5rem;
    font-size: 0.8rem;
  }
}

.chevron {
  margin-left: auto;
  display: flex;
  align-items: center;
  width: 1rem;

  & svg {
    fill: $color-text-light;
  }

  @media $(bp-medium-up) {
    display: none;
  }
}

.icon {
  display: none;

  @media $(bp-medium-up) {
    display: flex;
  }

  margin-right: 1rem;

  & svg {
    width: 1.3rem;
    height: 1.3rem;
    fill: $color-text-medium;
  }
}

.cheque {
  position: relative;
  top: -0.2em;
}
