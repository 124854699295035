@import '../../../styles/vars/colors';
@import '../../../styles/vars/sizing';
@import '../../../styles/vars/decoration';

$size-triangle: 10px;

.root,
.rootTransfer {
  padding: $space;
  color: $color-error;
  border-radius: $radius-global;
  border: 1px $color-error solid;
  margin-bottom: $space;
  position: relative;

  &:before,
  &:after {
    display: block;
    position: absolute;
    bottom: calc(-$size-triangle * 2);
    content: '';
    width: calc($size-triangle * 2);
    height: calc($size-triangle * 2);
    left: calc($space * 2);
    margin-left: -$size-triangle;
    border: $size-triangle solid transparent;
  }

  &:before {
    border-top-color: $color-error;
  }
}
.root:after {
  border-top-color: white;
  bottom: calc(-$size-triangle * 2 + 1px);
}

.rootTransfer:after {
  border-top-color: $color-bg-1;
  bottom: calc(-$size-triangle * 2 + 1px);
}
