@import '../../styles/vars/colors';
@import '../../styles/vars/sizing';
@import '../../styles/vars/breakpoints';
@import '../../styles/common/inner-container';

.root {
  background: $color-bg-2;
  padding: calc($space / 2) 0;
  color: white;

  @media $(bp-medium-up) {
    padding: $space 0;
  }

  @media print {
    display: none;
  }
}

.inner {
  composes: inner-container;
}

.button {
  margin-bottom: 0;

  @media $(bp-medium-up) {
    display: none;
  }
}

.children {
  margin-top: $space;

  @media $(bp-medium-up) {
    margin-top: 0;
  }
}
