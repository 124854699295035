@import '../../../styles/vars/colors';
@import '../../../styles/vars/sizing';

.list {
  display: flex;
  margin-bottom: $space;
  align-items: stretch;
}

.item {
  margin-right: 1rem;

  &:last-child {
    margin-right: 0;
  }
}

.anchor {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.5rem 2rem;
  border: 1px solid $color-line-medium;
  color: $color-text-medium;
  text-decoration: none;
  height: 100%;
}

.active {
  background: $color-action-1;
  color: white;
}

.backForward:active {
  background-color: $color-action-1;
  color: white;
}
