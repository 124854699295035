@import '../../styles/vars/sizing';
@import '../../styles/vars/typography';

.top {
  display: flex;
  justify-content: space-between;
}

.status {
  margin: calc($space * 2) 0;
}
