@import '../../../styles/vars/vars';

.block {
  margin: calc($space * 2) 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div:first-child {
    width: 90%;
  }
}

$width-circle: 1.5rem;

.icon {
  display: flex;
  width: $width-circle;
  height: $width-circle;
  border-radius: 1rem;
  align-items: center;
  justify-content: center;
  background: $color-text-medium;
  cursor: pointer;
  &.inline {
    @media $(bp-large-up) {
      margin-top: -$width-circle;
    }
  }
}
