@import '../../styles/vars/vars';
@import '../../styles/common/spin';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-weight: bold;
}

.text {
  color: $color-text-medium;
  width: 100%;
  text-align: center;
}

.icon {
  composes: spin;
  width: 2rem;
  height: 2rem;
  margin-bottom: calc($space/2);
  fill: $color-text-light;
}
