@import '../../styles/vars/vars';

.servicesView {
  padding-top: calc($space * 3);
}

.darkSection {
  background-color: $color-bg-1;
  margin-top: calc($space * 2);
  padding: calc($space * 2) 0;
}

.secondaryTitle,
.panelTitle {
  margin-bottom: calc($space * 2);
  font-family: $font-family-functional;
  letter-spacing: 0.5px;
}

.panelTitle {
  text-align: center;
}

.moreOptions {
  display: block;
  text-align: right;
  margin-right: calc($space * 2);
}

.dottedLine {
  border-top: dotted 2px $color-action-2-light;
}

.textArea {
  margin: calc($space * 1.2) auto calc($space * 1.2);

  & h3 {
    font-size: 1.4rem;
    font-weight: 600;
  }
}

.italicFont {
  font-style: italic;
}

.extraTopMargin {
  margin-top: calc($space * 2.2);
}

.boldTextArea {
  font-weight: 600;
}

.smallTextArea {
  font-size: 0.9rem;
}

.linkContainer {
  margin: calc($space * 1.2) auto;
}

.centerAlign {
  text-align: center;
}

.extraPadding {
  @media $(bp-extra-large-up) {
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}
