@import '../../styles/vars/vars';

.userDetails {
  margin: $space 0 calc($space * 2);
}

.userDetail {
  display: block;
}

.stripeyAlerts {
  margin: $space 0 calc(2 * $space);
  display: block;
}
