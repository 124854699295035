@import '../../styles/vars/colors';
@import '../../styles/vars/sizing';

.root :global .react-datepicker {
  border: 0;
}

.root :global .react-datepicker .react-datepicker__day {
  border-radius: 0;
  color: $color-text-medium;
}

.root :global .react-datepicker .react-datepicker__header {
  background-color: transparent;
  border: 0;
}

.root :global .react-datepicker .react-datepicker__day--in-selecting-range {
  background-color: $color-action-1-light;
  color: $color-text-medium;
}

.root
  :global
  .react-datepicker__month--selecting-range
  .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
  background-color: $color-bg-1;
  color: $color-text-medium;
}

.root :global .react-datepicker .react-datepicker__day--in-range {
  background-color: $color-action-1-light;
}

.root :global .react-datepicker .react-datepicker__day--outside-month {
  opacity: 0;
  color: $color-text-medium;
}

.root :global .react-datepicker-popper[data-placement^='bottom'] {
  margin-top: 10px;
  margin-left: 50px;
}
.root :global .react-datepicker .react-datepicker__current-month {
  font: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}
.root :global .react-datepicker .react-datepicker__day--keyboard-selected,
.root :global .react-datepicker .react-datepicker__day--range-end,
.root :global .react-datepicker .react-datepicker__day--range-start {
  color: white;
  background-color: $color-action-1;
}

.root :global .react-datepicker .react-datepicker__day--disabled {
  opacity: 0.5;
}

.root {
  color: $color-text-dark;
}
