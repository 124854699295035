@import '../../styles/vars/colors';
@import '../../styles/vars/breakpoints';
@import '../../styles/vars/sizing';

.root {
  margin-bottom: -$space;
}

.list {
  display: none;
  justify-content: space-between;

  @media $(bp-large-up) {
    display: flex;
  }

  & a {
    display: block;
    padding: 1rem 0.5rem 0.5rem;
    color: white;
    text-decoration: none;
    font-weight: 200;
    position: relative;

    &:after {
      display: none;
      position: absolute;
      bottom: -20px;
      content: '';
      width: 20px;
      height: 20px;
      left: 50%;
      margin-left: -10px;
      border: 10px solid transparent;
      border-top-color: $color-action-2;
    }
  }

  & .active {
    background-color: $color-action-2;

    &:after {
      display: block;
    }
  }
}
