@import '../../styles/vars/colors';
@import '../../styles/vars/sizing';
@import '../../styles/vars/breakpoints';

.root {
  display: block;
  color: $color-text-medium;
  font-weight: bold;
  padding: 0.8em 1rem;
  font-size: 1.3rem;
  cursor: pointer;

  @media $(bp-medium-up) {
    padding: 1em 2rem;
    font-size: 1rem;
  }

  & svg {
    height: 1rem;
    width: 1rem;
    margin-right: 0.5rem;
    fill: $color-text-medium;
  }

  &:hover {
    color: $color-text-dark;

    & svg {
      fill: $color-text-dark;
    }
  }
}
