@import '../../../styles/vars/sizing';
@import '../../../styles/vars/colors';
@import '../../../styles/vars/breakpoints';

.root {
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;
  font-size: 0.8rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  background: white;
  width: 100%;

  @media $(bp-large-up) {
    box-shadow: none;
    height: 100%;
    position: static;
    background: transparent;
  }

  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    width: 100%;
  }

  & li {
    flex: 1 0 50%;

    @media $(bp-large-up) {
      flex: auto;
      height: 100%;
    }
  }
}

.longText {
  display: none;

  @media $(bp-large-up) {
    display: inline;
  }
}
