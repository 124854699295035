@import '../../styles/vars/colors';
@import '../../styles/vars/breakpoints';

.tabs {
  display: flex;
  width: 100%;

  & li {
    flex: 1;
    margin-right: 5px;

    @media $(bp-large-up) {
      flex: 0 0 12rem;
    }
  }
}

.tab {
  display: block;
  background: $color-bg-2-dark;
  padding: 1rem 0;
  color: #5f6772;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  border-top: 5px solid $color-bg-2;
  text-decoration: none;
  cursor: pointer;

  @media $(bp-large-up) {
    width: 12rem;
  }

  &:hover {
    background-color: $color-bg-2-darker;
  }
}

.active {
  background: white;
  border-top-color: $color-action-1;
  cursor: default;

  &:hover {
    background: white;
  }
}
