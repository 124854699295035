@import '../../styles/vars/vars';

.fieldSetWrap,
.flexWrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.flexWrap {
  & input,
  & :global(.Select) {
    min-width: 14em;
    @media $(bp-small-only) {
      min-width: 70vw;
    }
  }
  & > :global(div) {
    margin-right: $space;
    @media $(bp-small-only) {
      margin-right: 0;
    }
  }
}

.isFull {
  & .stripTop {
    display: none;
  }
}

.confirmWrap {
  padding-top: 1.5em;

  & .stripBottom {
    display: none;
  }

  & .stripTop {
    display: block;
  }
}

.cancel {
  margin-left: 5px;
}
