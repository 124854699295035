@import '../../../styles/vars/sizing';
@import '../../../styles/vars/colors';

.root {
  position: relative;
  margin-bottom: $space;
}

input.input {
  margin-bottom: 0;
}
