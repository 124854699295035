@import '../../styles/vars/colors';
@import '../../styles/vars/typography';
@import '../../styles/vars/sizing';

$circle-size: 3rem;

.root {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.item {
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;

  &:before,
  &:after {
    display: block;
    content: '';
    position: absolute;
    border-top: 3px solid $color-action-1;
    top: calc($circle-size/2 - 1px);
    width: 50%;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }

  &:first-child:before {
    display: none;
  }

  &:last-child:after {
    display: none;
  }
}

.active {
  &:after {
    border-color: $color-text-light;
  }

  & ~ .item {
    &:before,
    &:after {
      border-color: $color-text-light;
    }

    & .circle {
      border-color: $color-text-light;
      color: $color-text-light;
    }
  }
}

.circle {
  width: $circle-size;
  height: $circle-size;
  border: 3px solid $color-text-light;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $circle-size;
  margin-bottom: 1rem;
  font-family: $font-family-functional;
  font-weight: bold;
  background: white;
  z-index: 1;
  color: $color-text-light;

  color: $color-action-1;
  border-color: $color-action-1;
}
