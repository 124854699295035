@import '../../styles/vars/sizing';
@import '../../styles/vars/colors';

.root {
  text-align: center;
  padding: calc($space * 3) 0;
}

.icon {
  width: 2rem;
  height: 2rem;
  margin-bottom: $space;
  fill: $color-text-light;
}
