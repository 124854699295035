/*
These are global classes and should be kept to a minimum, and solely to this file.
Add classes here when a pattern keep recurring inside of modules.
*/

@import './vars/sizing';

:global .inner-container {
  max-width: $width-inner;
  margin: 0 auto;
  padding: 0 $space;
  display: block;
}

:global .width-narrow {
  max-width: $width-narrow;
  margin: 0 auto;
}
