@import './vars/colors';
@import './vars/sizing';
@import './vars/breakpoints';

table {
  width: 100%;
  border-spacing: 0;
}

td {
  padding: 1rem 0;
  border: 0;
  border-bottom: 1px solid $color-line-light;
  padding-right: 1rem;
  vertical-align: top;

  &:last-child {
    padding-right: 0;
  }

  @media $(bp-medium-up) {
    &:first-child {
      padding-left: $space;
    }

    &:last-child {
      padding-right: $space;
    }
  }

  @media print {
    padding: 0.5rem 0;
  }
}

th {
  text-align: left;
  padding: 1rem 0 1rem;
  border-bottom: 1px solid $color-action-2;
  padding-right: 1rem;

  &:last-child {
    padding-right: 0;
  }

  @media $(bp-medium-up) {
    &:first-child {
      padding-left: $space;
    }

    &:last-child {
      padding-right: $space;
    }
  }
}
