@import '../../../styles/vars/sizing.css';
@import '../../../styles/vars/colors.css';
@import '../../../styles/vars/breakpoints.css';

.row {
  border: 1px solid $color-line-medium;
  border-radius: 30px;

  padding: 4px;
  gap: 4px;

  height: 48px;
  width: 310px;

  display: flex;
  align-items: center;
}

.baseButton {
  border-radius: 70px;
  width: 200px;
  height: 40px;
  margin: 0;

  &.inactive {
    background-color: $color-white;
    color: $color-black;
  }

  &.active {
    background-color: $color-action-2;
    color: $color-white;
  }
}

.error {
  font-size: 0.9rem;
  color: $color-error;
  display: block;
  margin-top: -0.3rem;
  margin-bottom: 0.3rem;
}
