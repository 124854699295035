.root {
  background: white;
}
.medium {
  input[type='text'],
  textarea {
    width: 310px;
    box-shadow: 0 3px #f0f0f0 inset;
    padding: 0 1rem;
    height: 40px;
    border: 1px solid #cccccc;
  }
}
