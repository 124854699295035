@import '../../styles/vars/colors';
@import '../../styles/vars/sizing';
@import '../../styles/vars/breakpoints';

$size-triangle: 12px;

.root {
  position: absolute;
  right: 0;
  background: white;
  border: 1px solid $color-line-medium;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  padding: $space;
  z-index: 1500;
  width: 100vw;
  margin-top: calc($space/3);
  text-align: center;

  @media $(bp-medium-up) {
    text-align: left;
    width: 30rem;
  }

  &:before,
  &:after {
    display: block;
    position: absolute;
    top: calc(-$size-triangle * 2);
    content: '';
    width: calc($size-triangle * 2);
    height: calc($size-triangle * 2);
    margin-left: -$size-triangle;
    border: $size-triangle solid transparent;
    right: 4.8rem;

    @media $(bp-medium-up) {
      right: 9.8rem;
    }
  }

  &:before {
    border-bottom-color: $color-line-medium;
    top: calc(-$size-triangle * 2 - 1px);
  }

  &:after {
    border-bottom-color: white;
  }
}

.item {
  padding-top: $space;
  padding-bottom: $space;
  border-bottom: 1px solid $color-line-medium;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: 0;
    padding-bottom: 0;
  }

  & p {
    margin-bottom: 0;
  }

  & h3 {
    font-weight: bold;
    font-size: 1rem;
  }
}

.noNots {
  text-align: center;
}
