@import '../../../styles/vars/sizing';
@import '../../../styles/vars/breakpoints';

.row {
  display: flex;
}

.inline {
  margin: 0 auto $space;
  align-items: center;
  position: relative;

  & .col {
    width: 100%;

    & > div {
      margin-bottom: 0;
    }
  }

  & input {
    margin-bottom: 0;
  }

  @media $(bp-large-up) {
    display: flex;

    & .label {
      position: absolute;
      width: 20rem;
      left: -20rem;
      padding-right: 1rem;
      text-align: right;
      margin: 0;
      display: flex;
    }
  }
}

.subLabel {
  position: relative;
  display: inline-block;
  top: -0.25rem;
  margin-bottom: 0.15rem;
}

.top {
  align-items: flex-start;

  @media $(bp-large-up) {
    & .label {
      margin-top: 0.3rem;
    }
  }
}

.copInformation {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  border: none;
  outline: 0;
  margin-bottom: 5px;
}
