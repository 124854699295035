.collapsed {
  height: 0;
  position: absolute;
  overflow: hidden;
}

.drawerSpacer {
  padding: 0 28px;
  @media print {
    display: none;
  }
}
