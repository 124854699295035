@import './vars/colors';
@import './vars/typography';

html {
  font-size: 14px;
}

body {
  font-family: $font-family-body;
}

h1 {
  font-family: $font-family-functional;
  letter-spacing: 0.5px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 1.8rem;
  font-size: $h1-font-size;
}

:global(h1.reduced) {
  font-family: $font-family-body;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}

:global(h1.underline) {
  border-bottom: 1px solid $color-text-dark;
  padding-bottom: 0.5rem;
}

h2 {
  font-family: $font-family-fancy;
  margin-top: 0;
  font-size: $h2-font-size;
}

h3 {
  font-size: $h3-font-size;
}

:global(h2.subheader) {
  margin-top: -1.2rem;
  margin-bottom: 1.8rem;
  color: $color-text-medium;
}

h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin-top: 0;
}

p {
  margin: 0 0 1rem 0;
  line-height: 1.5rem;
}

a {
  color: $color-action-2;
  text-decoration: underline;
}

:global(.copy) ul {
  list-style: disc;
}

ol,
:global(.copy) ul {
  padding-left: 2rem;
  margin-bottom: 1rem;

  & li {
    margin-bottom: 0.3rem;
  }
}
