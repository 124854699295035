@import '../../styles/vars/vars';

.root {
  padding: $space;
  text-align: center;
}

.heading {
  font-weight: 600;
  font-size: 1.1em;
  margin-bottom: 0.5em;
}

.body {
  margin-bottom: 2em;
}

.tel {
  font-size: 2em;
  font-family: $font-family-body;
  font-weight: 500;
}
