@import '../../styles/vars/vars';

$width-circle: 1.5rem;

.fieldsWrap {
  display: flex;
}

.fieldWrap {
  width: 2.6em;
  margin-right: 1em;
}

.help {
  margin-top: calc($space * 2);
}

.icon {
  display: flex;
  width: $width-circle;
  height: $width-circle;
  border-radius: 1rem;
  align-items: center;
  justify-content: center;
  background: $color-text-medium;
  cursor: pointer;
}

.tooltipWrap {
  margin-top: 0.2em;
}
