@import '../../styles/common/spin';
@import '../../styles/vars/colors';
@import '../../styles/vars/sizing';

.root {
  text-align: center;
  padding: calc($space * 5) 0;
}

.spinner {
  display: flex;
  justify-content: center;
  margin-top: calc($space * 2);

  & svg {
    width: 10rem;
    height: 10rem;
    fill: $color-line-medium;
  }
}

.icon {
  composes: spin;
}
