@import '../../../styles/vars/colors';
@import '../../../styles/vars/breakpoints';
@import '../../../styles/vars/sizing';

.inner {
  @media $(bp-medium-up) {
    margin-bottom: $space;
    align-items: center;
    display: flex;
  }
}

.column {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0;

  & .option {
    margin-bottom: $space;
  }
}

.option {
  margin-bottom: $space;

  @media $(bp-medium-up) {
    margin-right: 3rem;
    margin-bottom: 0;
  }

  & input {
    display: none;
  }

  & .label {
    font-weight: normal;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0;
  }

  & :checked + label {
    & .dot {
      border-color: $color-action-2;

      &:after {
        visibility: visible;
        transform: scale(1);
      }
    }
  }
}

.dot {
  display: block;
  border: 1px solid $color-text-light;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 99rem;
  margin-right: 0.5rem;
  position: relative;

  &:after {
    visibility: hidden;
    transition: 0.2s;
    transform: scale(0);
    content: '';
    position: absolute;
    left: 3px;
    right: 3px;
    top: 3px;
    bottom: 3px;
    background: $color-action-2;
    border-radius: 99rem;
  }
}
