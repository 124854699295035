@import '../../styles/vars/sizing';
@import '../../styles/vars/breakpoints';

$padding: calc($space/2);

.root {
  margin: 0 auto;
  max-width: $width-global;
  padding: $padding;

  @media $(bp-medium-up) {
    $padding: $space;
    padding: $padding;
  }

  @media print {
    max-width: none;
    padding: 0;
  }
}

.box {
  background: white;
  width: 100%;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);

  @media print {
    box-shadow: none;
    padding: $padding 0;
  }
}
