@import '../../styles/vars/vars';

.root {
}

.slideNav {
  position: absolute;
  width: 80vw;
  left: -80vw;
  height: 100vh;
  z-index: 1;
  transition: 0.3s;
  visibility: hidden;
}

.content {
  transition: 0.3s;
  position: relative;
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  cursor: hover;
  z-index: 10;
}

.slideOver {
  overflow: hidden;
  height: 100vh;

  & .content {
    transform: translate(80vw);
  }

  & .slideNav {
    transform: translate(80vw);
    visibility: visible;
    box-shadow: 5px -5px 10px rgba(0, 0, 0, 0.3);
  }

  @media $(bp-large-up) {
    overflow: default;
    height: auto;

    & .content {
      transform: none;
    }

    & .slideNav {
      transform: none;
      visibility: hidden;
      box-shadow: none;
    }
  }
}
