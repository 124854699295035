@import '../../styles/vars/colors';
@import '../../styles/vars/sizing';
@import '../../styles/common/spin';

.root {
  text-align: center;
  color: $color-text-light;
  font-weight: bold;
  margin: $space 0;

  @media print {
    display: none;
  }
}

.icon {
  width: 2rem;
  height: 2rem;
  margin-bottom: calc($space/2);
  fill: $color-text-light;
}

.large {
  width: 2.5rem;
}

.spin {
  composes: spin;
  width: 2.5rem;
}
