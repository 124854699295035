@import '../../styles/vars/colors';
@import '../../styles/vars/sizing';
@import '../../styles/vars/breakpoints';
@import '../../styles/common/inner-container';

.root {
  background: $color-bg-1-light;
  padding: $space 0;

  @media $(bp-medium-up) {
    padding: calc($space * 2) 0;
  }
}

.lTDRoot {
  background: $color-bg-1-light;
  padding: $space 0;

  @media $(bp-medium-up) {
    padding: calc($space * 2) 0;
  }

  @media print {
    padding: $space 0 calc($space * 3) 0;
  }
}

.inner {
  composes: inner-container;
}
