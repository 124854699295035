@import '../../styles/vars/colors';
@import '../../styles/vars/sizing';

.root {
  background: $color-bg-3;
  padding: calc($space * 2) $space;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
