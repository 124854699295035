@import '../../../styles/vars/vars';

.inner {
  @media $(bp-medium-up) {
    align-items: center;
    margin-bottom: $space;
    display: flex;
  }
}

.column {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0;

  & .option {
    margin-bottom: $space;
  }
}

.option {
  width: 100%;
  margin-bottom: $space;
  transition: opacity 0.2s ease;
  opacity: 1;

  @media $(bp-medium-up) {
    margin-right: 3rem;
    margin-bottom: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  & input {
    opacity: 0;
    position: absolute;
  }

  & .label {
    font-weight: normal;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0;
  }

  & :checked + label {
    & .box {
      border-color: $color-action-2;

      & svg {
        visibility: visible;
        transform: scale(1);
      }
    }
  }

  & :focus + label {
    & .box {
      outline: $focus-global;
    }
  }

  & :disabled + label {
    opacity: 0.6;
  }
}

.box {
  display: block;
  border: 1px solid $color-text-light;
  width: 1.8rem;
  min-width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.5rem;
  position: relative;

  & svg {
    visibility: hidden;
    transition: 0.2s;
    transform: scale(0);
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    fill: $color-action-2;
  }
}
