.root {
  display: block;
  width: 100%;

  & label {
    display: block;
    font-size: 0.8rem;
    font-weight: normal;
    margin-bottom: 0.2rem;
  }

  & :global(.Select) {
    margin: 0;
    width: 100%;
  }

  & :global(.Select-value-label),
  & :global(.Select-control),
  & :global(.Select-input),
  & :global(.Select-placeholder) {
    height: 2rem !important;
    line-height: 2rem !important;
  }
}
