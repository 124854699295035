@import '../../styles/vars/colors';
@import '../../styles/vars/sizing';
@import '../../styles/common/inner-container';

.root {
  padding-top: $space;
  composes: inner-container;

  @media $(bp-medium-up) {
    padding: calc($space * 2) $space;
    display: flex;
    justify-content: flex-start;
  }

  @media print {
    display: none;
  }

  & > a {
    display: block;
  }
}

.nav {
  display: none;
  color: $color-text-light;

  & a {
    color: $color-action-1;
    text-decoration: none;
  }

  @media $(bp-medium-up) {
    display: inline-flex;
  }
}

.list {
  display: flex;

  & li {
    margin-right: 0.5rem;

    &:after {
      content: '/';
      margin-left: 0.5rem;
    }

    &:last-child {
      margin-right: 0;

      &:after {
        display: none;
      }
    }
  }
}

.backStyle {
  padding: 0;
  margin-right: 1.5rem;
  display: flex;
  align-items: center;
  font-weight: 400;
}
