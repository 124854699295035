@import '../../styles/vars/vars';

.capsLock {
  background: $color-error;
  color: white;
  padding: 1em 1.3em;
  margin: -1em 0 1em;
  font-size: 0.9em;
  display: inline-block;
  border-radius: 4px;
  border: solid 1px;
}

.handleWhiteSpace {
  white-space: pre-line;
}
