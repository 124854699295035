@import '../../styles/common/inner-container';
@import '../../styles/vars/sizing';
@import '../../styles/vars/typography';
@import '../../styles/vars/colors';
@import '../../styles/vars/breakpoints';

.inner {
  padding-bottom: $space;
  composes: inner-container;

  @media $(bp-medium-up) {
    flex-direction: row;
  }

  @media $(bp-large-up) {
    padding-bottom: calc($space * 2);
  }

  & :global(.Select) {
    margin-bottom: 0;

    @media $(bp-medium-up) {
      display: none;
    }
  }
}
.innerLoanDeposit {
  @media print {
    padding-bottom: 0;
    padding-top: $space;
  }
}

.linksDropdown {
  position: relative;
  z-index: 2;

  @media print {
    display: none;
  }
}

.jumpoffs {
  display: none;

  @media $(bp-medium-up) {
    display: block;
  }

  @media print {
    display: none;
  }
}

.row {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;

  @media $(bp-medium-up) {
    flex: 1;
    flex-direction: row;
  }
}

.col {
  display: flex;
  flex-direction: column;
  margin-bottom: calc($space/2);

  @media $(bp-medium-up) {
    margin-bottom: 0;
    padding-right: $space;
  }

  &:last-child {
    margin-bottom: 0;
    padding-right: 0;
  }
}

.details {
  display: none;
  @media $(bp-large-up) {
    display: block;
  }
}
.mobDetails {
  @media $(bp-large-up) {
    display: none;
  }
}

.actions {
  margin-top: auto;
  padding-top: $space;
  display: none;

  @media $(bp-medium-up) {
    display: block;
  }

  @media print {
    display: none;
  }
}

.singleAction {
  display: block;
}

.message {
  @media $(bp-medium-up) {
    margin-top: $space;
    max-width: 16rem;
  }
}

.logoStacked {
  width: 12rem;
  display: none;

  @media print {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }
}
