@import '../../styles/vars/breakpoints';
@import '../../styles/common/inner-container';

.sticky {
  background: white;
  z-index: 1;
  position: relative;
  @media print {
    display: none;
  }
}

.stickyInner {
  background: white;
}

.mockTableHeader {
  composes: inner-container;
  background: white;
  display: none;

  @media $(bp-medium-up) {
    display: block;
  }

  & :global table {
    //table-layout: fixed;
  }

  @media print {
    visibility: hidden;
  }
}

.printHeader {
  display: none;

  @media print {
    display: block;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    margin-top: -3.8em;
  }
}
