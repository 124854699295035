@import '../../styles/vars/vars';

.root {
  position: absolute;
  top: 0;
  left: 50%;
  max-width: 600px;
  padding: calc($space * 1.2) calc($space * 1.5);
  transform: translate(-50%, 28px);
  -webkit-font-smoothing: antialiased;
  z-index: 999999;
  background: $color-bg-1;
  color: $color-warning;
  box-shadow: 0 2px 13px rgba(0, 0, 0, 0.3);

  & a {
    color: $color-action-1-dark;
  }
}

.button {
  margin-bottom: 0;
}
