@import '../../styles/vars/vars';
@import '../../styles/vars/sizing';
@import '../../styles/vars/typography';

.loading {
  padding-top: 20px;
}

.message {
  margin-top: $space;
}
