@import '../../styles/vars/vars';

.secondaryTitle {
  margin-bottom: calc($space * 2);
  font-family: $font-family-functional;
  letter-spacing: 0.5px;
}

.title {
  margin-top: 2rem;
  @media $bp-large-up {
    margin-top: 0;
  }
}

.moveDown {
  position: relative;
  top: 2.5rem;
}
