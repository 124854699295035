@import '../../../node_modules/react-select/dist/react-select.css';

.Select {
  margin-bottom: 1rem;
}

.Select-value-label,
.Select-input,
.Select-control,
.Select-placeholder {
  height: 3rem !important;
  line-height: 3rem !important;
}

.Select-placeholder {
  padding: 0 1rem;
}
