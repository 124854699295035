.abmodal {
  position: fixed; /* Stay in place */
  z-index: 99999; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: auto;
    padding-top: 50px; /* Location of the box */
  }
}

/* Modal Content */
.abmodal-content {
  background-color: #fefefe;
  margin: auto;
  border: 1px solid #888;
  width: 541px;
  height: 594px;
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: auto;
  }
}

.abheader {
  width: 100%;
  height: 55px;
  background: #f2f1ec;
  color: #313131;
  padding: 3%;
  font-size: 18px;
  font-weight: bold;

  @media only screen and (max-width: 600px) {
    background: white;
    font-weight: normal;
  }
}
/* The Close Button */
.abclose {
  float: right;
  font-size: 28px;
  margin-top: -7px;
  color: #bfa77c;
  font-weight: 300;
  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.abclose:hover,
.abclose:focus {
  color: #bfa77c;
  text-decoration: none;
  cursor: pointer;
}
.abmainContent {
  padding-top: 2%;
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 5%;
}
.abcontent {
  font-size: 12px;
  color: #666666;
}

.abheading {
  font-size: 16px;
  color: #666666;
  font-weight: 400;
}

.abavailableBalanceHeading {
  font-size: 14px;
  color: #bfa77c;
  text-align: left;
  font-weight: 500;
  text-decoration: none;
  cursor: auto;
  padding: 1px;
}

.abinfoModal {
  background: #bfa77c;
  color: white;
  border-radius: 50%;
  width: 19px;
  height: 19px;
  display: inline-block;
  text-align: center;
  margin-right: 8px;
  font-size: 15px;
  cursor: pointer;
}
.abavBalanceDiv {
  display: block;
  margin-bottom: 30px;
}

.abgoBack {
  display: none;
  @media only screen and (max-width: 600px) {
    display: block;
    margin-left: 12px;
    padding-top: 14px;
    font-size: 12px;
    cursor: pointer;
  }
}

.abiconClass {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-right: 5px;
}

.ableft {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.abHr {
  border: 1px solid #ddddde;
  display: none;
  @media only screen and (max-width: 600px) {
    display: none;
  }
}
