@import '../../styles/vars/vars';

.col {
  @media $(bp-medium-up) {
    width: 30%;
  }
}

.heading {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: calc($space * 2);
}

.title {
  margin-right: auto;
  padding-right: $space;
}

.paymentButton {
  margin-right: $space;
}
