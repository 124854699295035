.root {
  display: flex;
  justify-content: space-between;
}

.button {
  @media print {
    display: none;
  }
}

.buttons {
  display: flex;
}
