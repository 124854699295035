@import './vars/decoration';
@import './vars/colors';
@import './vars/sizing';
@import './common/inner-container';

:global form.narrow,
:global fieldset.narrow,
:global form div.narrow {
  max-width: $width-narrow;
  margin-left: auto;
  margin-right: auto;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0 0 1rem;
}

legend,
:global(.legend) {
  display: block;
  font-family: $font-family-fancy;
  font-size: $h2-font-size;
  line-height: calc($h2-font-size * 1.5);
  width: 100%;
  margin: 0 auto $space auto;
  border-bottom: 1px solid $color-action-2;
}

:global fieldset.border {
  border-bottom: 1px solid $color-line-medium;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

input[type='text'],
input[type='search'],
input[type='date'],
input[type='password'],
textarea {
  width: 100%;
  box-shadow: 0 3px #f0f0f0 inset;
  padding: 0 1rem;
  height: $height-input;
  border-radius: $radius-global;
  border: 1px solid #cccccc;
}

input::placeholder {
  color: $color-text-light;
}

textarea {
  min-height: 10rem;
  padding: 1rem;
}

:global .error input[type='text'],
:global .error input[type='search'],
:global .error input[type='date'],
:global .error input[type='password'],
:global .error .Select-control,
:global .error textarea {
  border-color: $color-error;
}

input,
select {
  margin-bottom: 1rem;
}
