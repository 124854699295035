@import '../../../styles/vars/sizing';
@import '../../../styles/vars/colors';

.root {
  display: flex;
  align-items: center;
}

.button {
  margin-bottom: 0;
  margin-right: calc($space * 2);
}

.nonClickable {
  pointer-events: none;
}

.refreshWarningText {
  color: $color-action-1;
  padding-top: $space;
}
