@import '../../styles/vars/sizing';
@import '../../styles/vars/colors';
@import '../../styles/vars/breakpoints';

.forgotLink {
  display: inline-block;
  margin: calc($space/2) 0 $space;

  @media $(bp-large-up) {
    margin: 0;
  }
}
