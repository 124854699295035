@import '../vars/sizing';
@import '../vars/breakpoints';

.inner-container {
  max-width: $width-inner;
  margin: 0 auto;

  padding: 0 calc($space/2);

  @media $(bp-medium-up) {
    padding: 0 $space;
  }
}
