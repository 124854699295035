@import '../../../styles/vars/vars';

.row {
  @media print {
    page-break-inside: avoid;
  }
}

.clickable {
  cursor: pointer;
  &:hover {
    background: $color-action-3;
  }
  & td:first-child {
    text-decoration: underline;
    font-weight: bold;
  }
}

.icon {
  & svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: $color-text-medium;
  }
}

.extra {
  color: $color-text-light;
  display: block;
}

.wrap {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

.noWrap {
  white-space: nowrap;
}

.ellipsis {
  hyphens: auto;
  white-space: pre-wrap;
}

.actions {
  display: flex;
  justify-content: center;

  & li {
    margin-right: $space;

    &:last-child {
      margin-right: 0;
    }
  }
}

.colLink {
  color: inherit;
  text-decoration: none;
}

.drawerWrap {
  @media $(bp-small-only) {
    width: 1%;
  }
  @media print {
    display: none;
  }
}

.drawerButton {
  font-size: 1.2em;
  font-weight: 400;
  padding: 0.2rem 0.2rem 0.2rem 0.3rem;
  text-align: center;
  width: 28px;
  margin: 0;
}

.drawerText {
  margin-top: 0.5em;
  color: $color-text-medium;
}
