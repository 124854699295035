@import '../../../styles/vars/colors';
@import '../../../styles/vars/breakpoints';

.root {
  font-size: 0.8rem;
  line-height: 1.2rem;
  text-align: right;
  display: none;

  @media $(bp-large-up) {
    display: block;
  }

  & span {
    display: block;
  }
}

.light {
  color: $color-text-light;
}
