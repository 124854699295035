@import '../../styles/vars/sizing';
@import '../../styles/vars/colors';

.root {
  border: 1px solid $color-line-medium;
  padding: calc($space * 2);
  margin-bottom: calc($space * 2);

  & *:last-child {
    margin-bottom: 0;

    & *:last-child {
      margin-bottom: 0;
    }
  }

  & *:first-child {
    margin-top: 0;

    & *:first-child {
      margin-top: 0;
    }
  }
}
