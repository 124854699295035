.list {
  display: flex;
}

.item {
  padding-right: 0.5rem;

  &:after {
    display: inline-block;
    padding-left: 0.5rem;
    content: '-';
  }

  &:last-child {
    padding-right: 0;

    &:after {
      display: none;
    }
  }
}
