@import '../../styles/vars/breakpoints';
@import '../../styles/vars/sizing';

.root {
  margin-top: calc($space * 2);

  &:last-child {
    &:after {
      display: none;
    }
  }
}

.recurringStripey {
  padding-top: calc($space * 2);
}
