@import '../../styles/vars/vars';

.row {
  @media $(bp-large-up) {
    display: flex;
  }
}

.colWide {
  flex: 3;
}

.tableFix {
  margin: 0 calc(-$space/2);

  @media $(bp-medium-up) {
    margin: 0 -$space;
  }
}

.allButton {
  display: flex;
  justify-content: center;
  margin-top: $space;
}

.colNarrow {
  flex: 1;
  padding-left: calc($space * 2);
}

.fileField {
  margin: calc($space * 2) 0;
}

.top {
  margin-bottom: calc($space * 3);
}

.after3pmMessage {
  color: $color-error;
}
