@import '../../../styles/vars/colors';
@import '../../../styles/vars/sizing';

.root {
  margin: 0 0 calc($space * 2) 0;

  & dt {
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
  }

  & dd {
    margin: 0;
    border-bottom: 1px solid $color-line-medium;
  }

  & a {
    display: block;
    color: $color-text-dark;
    color: black;
    text-decoration: none;
    padding: 0.5rem;

    &:hover {
      background-color: $color-action-3;
    }
  }
}

.topMenuNew {
  & a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.services {
  margin: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;

  & dd {
    display: flex;
    align-items: center;
    height: 3.5rem;
  }
}

.invisible {
  opacity: 0;
}
