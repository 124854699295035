@import '../../styles/vars/colors';
@import '../../styles/vars/typography';
@import '../../styles/vars/sizing';
@import '../../styles/vars/breakpoints';

.root {
  background: $color-bg-2;
  padding: calc($space * 1.5) $space;
  text-align: center;

  @media $(bp-medium-up) {
    padding: calc($space * 1.5) 15%;
  }

  & p {
    color: #ccc;
  }

  p:last-child {
    margin-bottom: 0;
  }

  & a {
    color: white;
  }
}

.title {
  color: white;
  font-size: $h1-font-size;
  font-weight: bold;
  font-family: $font-family-body;
}
