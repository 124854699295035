@import '../../../styles/vars/colors';
@import '../../../styles/vars/breakpoints';
@import '../../../styles/vars/sizing';

.root {
  display: block;
  text-decoration: none;
  position: relative;
  top: 1px;
  padding: 0;

  &:hover .inner {
    opacity: 1;
  }

  @media $(bp-large-up) {
    padding: 0 $space;
  }
}

.inner {
  position: relative;
  top: -1px;
  display: flex;
  align-items: center;
  opacity: 0.4;
  font-weight: bold;
  color: $color-text-medium;
  border-top: 3px solid transparent;
  border-bottom: 0;
  justify-content: center;
  padding: 1rem;

  &:first-child {
    border-right: 1px solid $color-line-light;
  }

  @media $(bp-large-up) {
    border-top: 0;
    border-bottom: 3px solid transparent;
    padding: 0 0.2rem;
    justify-content: flex-start;
    padding-bottom: 0.5rem;

    &:first-child {
      border-right: 0;
    }
  }

  & svg {
    height: 1rem;
    width: 1rem;
    margin-right: 0.5rem;
    fill: $color-text-medium;
  }
}

.active .inner {
  opacity: 1;
  border-top-color: $color-action-1;
  color: #103d1d;

  @media $(bp-large-up) {
    border-top: 0;
    border-bottom-color: $color-action-1;
  }

  & svg {
    fill: #103d1d;
  }
}
