@import '../../styles/vars/vars';

.root {
  color: inherit;

  & > h2 {
    margin: 2.5rem auto 0;
    font-size: 1.7rem;
  }
}

.linkContainer {
  margin: 0 auto calc($space * 1.2);
  padding: calc($space * 1.2) 0 calc($space * 2.9);
}

.linkList {
  display: flex;
  flex-direction: column;
  @media $bp-large-up {
    flex-direction: row;
  }

  & li {
    margin: calc($space * 1.2) 0 auto;
    width: 100%;
    max-width: 20rem;
    @media $bp-large-up {
      margin: calc($space * 1.2) calc($space * 0.9) auto;
      width: auto;
      max-width: 100%;
    }
  }

  & li:first-child {
    margin-left: 0;
  }

  & li:last-child {
    margin-right: 0;
  }
}

.link {
  margin-top: auto;
  text-decoration: none;
  color: $color-white;
  border: solid 2px;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  padding: 1rem 1.5rem;
  width: 100%;
  font-weight: 500;
  font-family: $font-family-functional;
  max-width: 100%;
  min-width: calc($space * 8.8);
  text-transform: uppercase;
  border-color: #bfa77c;
  background-color: #bfa77c;
  letter-spacing: 1px;
}

.addPadding {
  padding: 0 2rem;

  & h2 {
    font-family: $font-family-functional;
  }
}

.contactText {
  color: $color-text-light;
  font-weight: 500;
  margin-top: 2.5rem;
  max-width: 55rem;
  width: 100%;

  & a {
    color: $color-green-1;
  }
}
