@import '../../styles/vars/sizing';
@import '../../styles/vars/colors';

.root {
  margin-bottom: calc($space * 3);
  text-align: center;
  background: $color-bg-1;
  border-top: 8px solid $color-warning;
  font-weight: bold;
  color: $color-warning;
  padding: $space;
}
