@import '../../styles/vars/colors';
@import '../../styles/vars/sizing';
@import '../../styles/vars/breakpoints';

.tabs {
  display: block;
  background: $color-bg-2;
  padding: $space $space 0 $space;
  margin-bottom: $space;

  @media $(bp-large-up) {
    display: none;
  }
}
