@import '../../styles/vars/colors';
@import '../../styles/vars/breakpoints';
@import '../../styles/vars/sizing';
@import '../../styles/common/loading-empty';

.root {
  position: relative;

  @media $(bp-medium-up) {
    align-items: center;
    display: flex;
  }
}

.rowTitle {
  margin: 0 0 0.2rem;
  font-weight: bold;
  font-size: 1rem;

  & a {
    color: $color-text-dark;
  }
}

.details {
  color: $color-text-light;
  display: block;

  @media $(bp-small-only) {
    margin-bottom: 1rem;
  }
}

.titleCol {
  flex: 0 0 40%;
  padding: $space $space 0 $space;

  @media $(bp-medium-up) {
    padding: 0;
  }
}

.balanceCol {
  flex: 0 0 30%;
  margin-left: auto;
  font-size: 1.1rem;
  padding: 0 $space $space $space;

  @media $(bp-medium-up) {
    font-size: 1rem;
    padding: 0;
    text-align: right;
  }
}

.actionCol {
  border-top: 1px solid $color-line-light;
  padding: $space;

  &:empty {
    display: none;
  }

  @media $(bp-medium-up) {
    padding: 0;
    border: 0;

    flex: 0 0 8rem;
    margin-left: auto;
    text-align: right;

    &:empty {
      display: block;
    }
  }
}

.empty {
  composes: loading-empty;
}

/*
A bit hacky: Absolutely positioned overlay link for
mobile so whole top of card is clickable
*/
.mobViewLink {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 3rem;

  @media $(bp-medium-up) {
    display: none;
  }
}
