@import '../../styles/vars/colors';
@import '../../styles/vars/breakpoints';
@import '../../styles/vars/vars';
.root {
  padding: 16px 24px 16px 24px;
  background-color: $color-bg-1;
  color: $color-text-dark;
  font-family: $font-family-body;
  font-size: 13px;
  line-height: 1.5;
  font-weight: 400;
  margin-bottom: 1em;
  text-align: justify;
  .mobileContent {
    display: none;
  }
  @media (max-width: 768px) {
    .mobileContent {
      display: block;
    }
    .desktopContent {
      display: none;
    }
  }
}
