@import '../../styles/vars/breakpoints';
@import '../../styles/vars/sizing';

.filtersButton {
  margin-bottom: 0;
  margin-right: $space;

  &:last-child {
    margin-right: 0;
  }

  @media $(bp-medium-up) {
    width: 12rem;
    margin-top: $space;
  }
}

.clearButton {
  flex: 0;
  transition: 0s;

  @media $(bp-medium-up) {
    flex: 0 8rem;
    width: 8rem;
  }
}

.invisible {
  display: none;
}

.bottom {
  padding: $space;
  display: flex;
  justify-content: center;

  @media print {
    display: none;
  }
}

.row {
  margin-bottom: $space;

  @media $(bp-medium-up) {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
  }
}

.col1 {
  @media $(bp-medium-up) {
    flex: 1;
    width: 100%;
  }

  @media $(bp-large-up) {
    margin-bottom: 0;
    flex: 1;
  }
}

.col2 {
  display: flex;
  justify-content: flex-end;
}

.tabsNav {
  width: 100%;
  margin-bottom: calc(0rem - calc($space/2));

  @media $(bp-medium-up) {
    margin-bottom: calc(0rem - $space);
  }
}
