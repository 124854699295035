@import '../../styles/vars/sizing';
@import '../../styles/vars/breakpoints';
@import '../../styles/common/inner-container';

.root {
  display: flex;
  composes: inner-container;

  flex-direction: column;

  @media $(bp-large-up) {
    flex-direction: row;
  }
}

.col {
  @media $(bp-large-up) {
    width: 50%;
    flex: 0 1 50%;

    &:first-child {
      margin-right: calc($space * 2);
    }
  }
}

.edit {
  display: block;
  margin: $space 0 calc($space * 2) 0;
}

.cancel {
  margin-left: 5px;
}

.modalButtonsAlignCenter {
  text-align: center;
}
