@import '../../styles/vars/colors';
@import '../../styles/vars/sizing';

$circle-size: 2.5rem;

.root {
  border-top: 8px solid;
  padding-top: $space;
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: center;
  text-align: justify;
  font-size: 0.8rem;
  line-height: 1.5;
}

.rootSuccess {
  color: $color-success;
  border-color: $color-success;
}

.rootFail {
  color: $color-error;
  border-color: $color-error;
}

.icon {
  border: 3px solid;
  margin-right: 0.5rem;
  border-radius: $circle-size;
  height: $circle-size;
  width: $circle-size;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconSuccess {
  border-color: $color-success;
  & svg {
    fill: $color-success;
    width: 2.1rem;
  }
}

.iconFail {
  border-color: $color-error;
  & svg {
    fill: $color-error;
    width: 1.1rem;
  }
}
