@import '../../styles/vars/vars';

.reference {
  font-family: $font-family-functional;
  font-size: 1.1em;
  font-weight: 600;
  margin-bottom: 2em;
}

.infoBlocks {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2.5em;
}
