@import '../../styles/vars/colors';
@import '../../styles/vars/breakpoints';
@import '../../styles/common/loading-empty';

.root {
  font-size: 0.9rem;
  border: 1px solid $color-line-medium;
}

.header {
  padding: 1rem 1.5rem;

  & h2 {
    margin-bottom: 0;
  }

  border-bottom: 1px solid $color-line-medium;
}

.footer {
  padding: 1rem 1.5rem;
  border-top: 1px solid $color-line-medium;
  color: $color-text-medium;
  font-style: italic;
}

.row {
  border-bottom: 1px solid $color-line-medium;
  margin-bottom: 0;

  @media $(bp-medium-up) {
    padding: 1.5rem;
  }

  &:last-child {
    border: 0;
  }
}

.empty {
  composes: loading-empty;
}
