@import '../../styles/vars/colors';

.formActionButton {
  height: 48px;
  padding: 16px 26px;
  text-align: center;
  text-decoration: underline;
  color: $color-action-2;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
}
