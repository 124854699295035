@import '../../../styles/vars/sizing';
@import '../../../styles/vars/breakpoints';

.root {
  display: flex;
  flex-direction: column;

  @media $(bp-large-up) {
    flex-direction: row;
  }
}

.row {
  display: flex;
  flex-direction: column;

  @media $(bp-medium-up) {
    flex-direction: row;
  }
}

.col {
  flex: 1;
  margin-right: $space;

  &:last-child {
    margin-right: 0;
  }
}
