@import '../../styles/vars/sizing';

.address {
  & span {
    display: block;
  }
}

.bottom {
  margin: $space 0;
}
