@import '../../styles/vars/colors';
@import '../../styles/vars/sizing';
@import '../../styles/vars/breakpoints';
@import '../../styles/vars/typography';

.root {
  & li {
    border-bottom: 1px solid $color-line-medium;

    &:first-child {
      & .button {
        padding-top: 0;
      }
    }

    &:last-child {
      border: 0;

      & .button {
        padding-bottom: 0;
      }
    }
  }

  @media $(bp-medium-up) {
    max-width: 16rem;

    & li {
      border: 0;
    }
  }
}

.button {
  display: flex;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  color: $color-text-dark;
  padding: 1rem 0;
  font-size: 0.9rem;
  font-weight: bold;
  letter-spacing: $letter-spacing-action;
}

.icon {
  margin-right: 1rem;

  & svg {
    width: 1.8rem;
    height: 1.8rem;
    fill: $color-text-medium;
  }
}

.chevron {
  margin-left: auto;
  width: 1.5rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & svg {
    fill: $color-text-dark;
    height: 100%;
    width: 1rem;
  }
}
