@import '../../styles/vars/vars';

.psdPartyId {
  margin-top: 20px;
  display: inline-block;
}

.psdErrorDiv {
  width: auto;
  height: auto;
  padding: 10px;
  border: 1px solid #bfa77c;
  color: #bfa77c;
  text-align: center;
  margin: 13px;
}
.psdInfoUl {
  list-style: disc;
  margin-left: 30px;
}

.psdInfoLi {
  padding-left: 10px;
}
.psdInfoEmailId {
  color: #bfa77c;
}

.tabMainDivMobile {
  display: none;
  @media only screen and (max-width: 600px) {
    display: block;
  }
}

.tabMainDivDesktop {
  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.psdImageDiv {
  width: 449px;
  height: auto;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}
.psdSpinner {
  margin: 30px;
}
.psdHeading1 {
  margin-top: -16px;
  font-size: 16px;
}
.psdHeading2 {
  font-size: 13px;
}
.psdInfo {
  font-size: 13px;
  color: #bfa77c;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}
.psdInfoModal {
  background: #bfa77c;
  color: white;
  border-radius: 50%;
  width: 19px;
  height: 19px;
  display: inline-block;
  text-align: center;
  margin-right: 8px;
  font-size: 15px;
  cursor: pointer;
}
.psdImageDiv {
  margin-bottom: 50px;
}
.psdImgSpan {
  height: 96px;
  width: 449px;
  display: block;
  background: #ffffff;
  border: 1px solid #e6ebeb;
  margin-top: 15px;
  padding: 5%;
  cursor: pointer;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}
.psdGoBack {
  text-align: center;
  margin-top: 135px;
  margin-right: 35px;
}
.psdImgSpan:hover {
  box-shadow: 5px 2px 2px #e6ebeb;
}
.psdImgDiv:hover {
  box-shadow: 5px 2px 2px #e6ebeb;
}

.psdImage {
  width: 136px;
  height: 50px;
}

.psdiconClass {
  border: solid #bfa77c;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-right: 5px;
  float: right;
  margin-top: 20px;
}

.psdright {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.tabMainDiv {
  height: 79px;
  background: #414347;
  display: flex;
  align-items: flex-end;
  padding-left: 60px;
  margin-top: 20px;
  @media only screen and (max-width: 600px) {
    display: block;
    padding-left: 0px;
    height: 109px;
  }
}

.psdTab {
  width: 172px;
  height: 49px;
  background: #ffffff;
  border-top: 4px solid #47b6a0;
  font-size: 12px;
  color: #45b6a1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 600px) {
    width: 96%;
    margin-left: 2%;
  }
}

.psdTabHeaderSpan {
  margin-bottom: 16px;
  display: block;
}
.psdTabInfo {
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  height: 52px;
  @media only screen and (max-width: 600px) {
    display: inline-block;
    height: auto;
    width: 50%;
    margin-top: 12px;
    margin-left: 10px;
  }
}

.psdselectAll {
  border: 1px solid white;
  color: white;
  width: 120px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-bottom: 15px;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  @media only screen and (max-width: 600px) {
    display: inline-block;
    text-align: center;
    padding-top: 7px;
    height: auto;
    padding-bottom: 7px;
    position: absolute;
    margin-top: 10px;
    margin-left: 50px;
  }
}

.psddeselectAll {
  border: 1px solid black;
  color: black;
  width: 135px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-bottom: 15px;
  border-radius: 5px;
  cursor: pointer;
  background: white;
  text-transform: uppercase;
  @media only screen and (max-width: 600px) {
    display: inline-block;
    text-align: center;
    padding-top: 7px;
    height: auto;
    padding-bottom: 7px;
    position: absolute;
    margin-top: 10px;
    margin-left: 40px;
  }
}

.AccountPermissionsDiv {
  display: block;
  padding-left: 8%;
  padding-right: 8%;
  margin-bottom: 40px;
  margin-top: 25px;
}
.accountCardBox {
  height: 192px;
  padding-top: 20px;
  border-bottom: 1px solid #ddddde;
}

.psdAccountName {
  font-size: 14px;
  font-weight: bold;
}
.psdAccountNumber {
  font-size: 14px;
  color: #6f6f6f;
  margin-top: 2px;
}

.accountCardBox:nth-child(1) {
  border-top: 1px solid #ddddde;
}

.psdCheckboxDiv {
  padding-top: 30px;
  padding-left: 50px;
}

.psdCheckboxSpan {
  margin-bottom: 5px;
  display: block;
}

.psdProvideAccess {
  margin-top: 30px;
  display: block;
}

.accountCardBoxView {
  min-height: 77px;
  padding-top: 20px;
  border: 1px solid #ddddde;
  padding-left: 7%;
  margin-bottom: 20px;
  @media only screen and (max-width: 600px) {
    padding: 5%;
  }
}
.psdViewMode {
  display: flex;
  @media only screen and (max-width: 600px) {
    display: block;
  }
}
.psdViewLeft {
  width: 50%;
}

.psdAccountView {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.psdViewAccessTo {
  font-size: 12px;
  color: #666666;
}
.psdViewRight:last-child {
  margin-bottom: 20px;
  @media only screen and (max-width: 600px) {
    margin-bottom: 0px;
    margin-top: 12px;
    display: block;
  }
}
.psdViewPermissions {
  font-size: 14px;
  margin-top: 5px;
}

.psdAmend {
  margin-left: 50px;
  color: #bfa77c;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}
.selectedAccountName {
  margin-left: 8%;
  margin-top: 20px;
  border-top: 1px solid #d8d8d8;
  width: 84%;
  display: flex;
  align-items: center;
  height: 80px;
  font-size: 21px;
  color: #4a4a4a;
  margin-bottom: -25px;
  @media only screen and (max-width: 600px) {
    height: 60px;
    font-size: 17px;
  }
}

.psdSuccess {
  background: url('../../assets/svg/tick.svg');
  display: inline-block;
  width: 49px;
  height: 49px;
  vertical-align: middle;
  margin-right: 10px;
}

.genericoverlaymainContent {
  padding-top: 2%;
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 5%;
}
.genericoverlaycontent {
  font-size: 12px;
  color: #666666;
}

.genericoverlayheading {
  font-size: 16px;
  color: #666666;
  font-weight: 400;
}

.genericoverlayavailableBalanceHeading {
  font-size: 14px;
  color: #bfa77c;
  text-align: left;
  font-weight: bold;
}

.genericoverlayHr {
  border: 1px solid #ddddde;
  display: none;
  @media only screen and (max-width: 600px) {
    display: none;
  }
}
