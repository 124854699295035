@import '../../styles/vars/decoration';
@import '../../styles/vars/colors';
@import '../../styles/vars/typography';
@import '../../styles/vars/sizing';
@import '../../styles/common/spin';

.root {
  display: inline-block;
  border-radius: $radius-global;
  color: white;
  text-align: center;
  padding: 1rem 1.5rem;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  font-family: $font-family-functional;
  letter-spacing: $letter-spacing-action;
  border: 1px solid transparent;
  font-size: 0.9rem;
  background-color: $color-action-1;
  margin-bottom: $space;
  position: relative;
  cursor: pointer;
  transition: 0.2s;
  line-height: 1rem;

  &:hover {
    background-color: $color-action-1-light;
  }

  &.disabled {
    background-color: $color-action-1;
  }
}

.primary {
  background-color: $color-action-1;

  &:hover {
    background-color: $color-action-1-light;
  }

  &.disabled {
    background-color: $color-action-1;
  }
}

.secondary {
  background-color: $color-action-2;

  &:hover {
    background-color: $color-action-2-light;
  }

  &.disabled {
    background-color: $color-action-2;
  }
}

.dark {
  background-color: $color-action-4;

  &:hover {
    background-color: $color-action-4-light;
  }

  &.disabled {
    background-color: $color-action-4;
  }
}

.ghost-light {
  background: transparent;
  border: 1px solid white;
  color: white;

  &:hover {
    background-color: transparent;
  }
}

.ghost-dark {
  background: white;
  border: 1px solid $color-text-dark;
  color: $color-text-dark;

  &:hover {
    background-color: $color-grey-1;
  }

  &.disabled {
    background-color: $color-grey-1;
  }
}

.ghost-gold {
  background: transparent;
  border: 1px solid $color-action-2;
  color: $color-action-2-dark;
  font-weight: 400;

  &:hover {
    background-color: $color-grey-1;
  }

  &.disabled {
    background-color: $color-grey-1;
  }
}

.spinContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  composes: spin;
  width: 2rem;
  height: 2rem;

  & svg {
    width: 2rem;
    height: 2rem;
    fill: white;
  }
}

.spinInner {
  transition: 0.3s;
  transform: scale(2);
  opacity: 0;
}

.inner {
  transition: 0.3s;
}

.loading {
  & .spinInner {
    transform: scale(1);
    opacity: 1;
  }

  & .inner {
    opacity: 0;
    transform: scale(0.5);
  }

  background-color: $color-action-2-dark !important;
}

.small {
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
}

.expand {
  width: 100%;
}

.light {
  background: white;
  color: $color-text-dark;
  font-weight: normal;
}

.disabled {
  opacity: 0.5;
  cursor: default;
}
