@import '../../styles/vars/sizing';
@import '../../styles/vars/breakpoints';
@import '../../styles/vars/typography';
@import '../../styles/common/inner-container';

.inner {
  composes: inner-container;
  padding-top: calc($space * 2);
}

.row {
  display: flex;
  flex-direction: column;

  @media $(bp-medium-up) {
    flex-direction: row;
  }
}

.col {
  margin: 0 0 1rem 0;

  @media $(bp-medium-up) {
    margin: 0 1rem 0 0;
  }
}

.width6 {
  width: 50%;
}

.width4 {
  @media $(bp-medium-up) {
    width: 50%;
  }

  @media $(bp-large-up) {
    width: 33.33%;
  }
}
