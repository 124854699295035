@import '../../styles/vars/vars';

.root {
  min-width: 8.7em;
  height: 10em;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: solid 1px $color-text-dark;
  border-radius: 4px;
  color: $color-text-dark;
  background: none;
  cursor: pointer;
  margin-left: 8px;
  margin-right: 8px;

  &:hover {
    background: $color-grey-1;
  }

  @media $(bp-small-only) {
    height: auto;
    flex-direction: row;
    margin-bottom: 1em;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5em;
}

.image {
  width: 4em;

  @media $(bp-small-only) {
    width: 2.2em;
    margin-right: 0.8em;
  }
}

.text {
  margin-top: 1em;

  @media $(bp-small-only) {
    display: none;
  }
}

.mobileText {
  display: none;
  @media $(bp-small-only) {
    display: block;
  }
}
