@import '../../styles/vars/colors';
@import '../../styles/vars/sizing';
@import '../../styles/vars/breakpoints';
@import '../../styles/vars/typography';

.root {
  color: $color-text-medium;

  @media $(bp-large-up) {
    margin-bottom: 0;
  }

  & strong {
    font-weight: normal;
    color: $color-text-dark;
  }

  & li {
    margin-bottom: $space;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.title {
  & span {
    font-size: 1.3rem;
    line-height: 1rem;
    margin-bottom: 0;
    color: $color-text-dark;
    font-weight: bold;
  }
}

.long {
  & strong {
    color: $color-text-medium;
    font-weight: bold;
    display: block;
  }
}
