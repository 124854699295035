@import '../../styles/vars/vars';

.title {
  font-size: 1.8em;
}

.allErrors {
  margin-bottom: 1em;
}

.subDescription {
  margin-bottom: 1em;
  display: inline-block;
}

.recordErrors {
  margin-bottom: 3em;
}

.otherError {
  border: solid $color-error 1px;
  border-radius: 4px;
  padding: $space;
  margin-bottom: $space;
}
