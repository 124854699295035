@import '../../../styles/vars/decoration';
@import '../../../styles/vars/sizing';
@import '../../../styles/vars/colors';

.root {
  display: block;
  position: relative;
  margin-bottom: $space;
}

.input {
  margin-bottom: 0;
}

.error {
  &:after {
    content: 'x';
    position: absolute;
    right: $space;
    font-size: 1.2rem;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    color: $color-error;
  }
}
