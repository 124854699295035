@import '../../styles/vars/colors';
@import '../../styles/vars/sizing';
@import '../../styles/vars/breakpoints';
@import '../../styles/vars/typography';

$size-triangle: 12px;

.root {
  background: $color-action-1;
  color: white;
  display: none;

  @media $(bp-large-up) {
    display: block;
  }
}

.inner {
  max-width: $width-global;
  padding: 0 $space;
  margin: 0 auto;

  & p {
    margin-bottom: 0;
  }
}

.row {
  display: flex;
}

.image {
  flex: 0 0 60%;
  font-size: 0;
}

.text {
  padding: $space;
  position: relative;
  padding-right: 3rem;

  &:before {
    display: block;
    content: '';
    left: 0;
    position: absolute;
    top: 50%;
    left: calc($size-triangle * -2);
    margin-top: -12px;
    border: $size-triangle solid transparent;
    border-right-color: $color-action-1;
  }
}

.triangleTop {
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    right: $space;
    border: $size-triangle solid transparent;
    border-bottom-color: $color-action-1;
  }
}

.close {
  font-family: $font-family-functional;
  font-size: 1.5rem;
  text-decoration: none;
  color: white;
  border: 1px solid white;
  width: 2rem;
  height: 2rem;
  display: block;
  line-height: 1.7rem;
  text-align: center;
  border-radius: 2rem;
  position: absolute;
  right: 0;
  top: calc($space/2);
}
