@import '../../styles/vars/sizing';
@import '../../styles/vars/colors';
@import '../../styles/vars/breakpoints';

.row {
  padding-bottom: $space;
  margin-bottom: $space;
  border-bottom: 1px solid $color-line-medium;

  @media $(bp-medium-up) {
    display: flex;
    padding-bottom: calc($space * 2);
    margin-bottom: calc($space * 2);
  }
}

.item {
  margin-bottom: $space;

  @media $(bp-medium-up) {
    margin-right: calc($space * 2);
    margin-bottom: 0;
  }
}

.form {
  min-width: 20rem;
  position: relative;
  z-index: 2;
}

.miniIcons {
  height: 15px;
  width: 15px;
  margin-right: 7px;
  vertical-align: middle;
  margin-top: -3px;
}

.warningMessage {
  font-family: Arial, serif;
  font-size: 10px;
  font-weight: 700;
  line-height: 11.5px;
  text-align: left;
}

.successMessage {
  font-family: Arial, serif;
  font-size: 10px;
  font-weight: 700;
  line-height: 11.5px;
  text-align: left;
  color: #41beae;
}

.mt10 {
  margin-top: 10px;
}
