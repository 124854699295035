@import '../../../styles/vars/colors';
@import '../../../styles/vars/sizing';

.root {
  padding: calc($space/2);
}

.card {
  padding: $space;
  border: 1px solid $color-line-light;
  margin-bottom: calc($space/2);
  position: relative;
}

.hasCheckbox {
  padding-right: calc($space * 5);

  & .type {
    right: calc($space * 5);
  }
}

.checkbox {
  position: absolute;
  right: $space;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;

  & label {
    margin: 0;
  }
}

.date {
  display: block;
  font-size: 0.9rem;
  color: $color-text-medium;
}

.type {
  position: absolute;
  width: 2rem;
  right: $space;
  top: calc($space/2);
}

.ref {
  color: $color-text-light;
}

.title {
  display: flex;
  justify-content: space-between;
  font-size: 1.3rem;
  margin: 0.8rem 0 0.2rem;
}
