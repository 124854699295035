@import '../../styles/vars/colors';
@import '../../styles/vars/sizing';
@import '../../styles/vars/breakpoints';

.root {
  position: relative;
  padding-right: 6rem;

  &.respond {
    @media $(bp-medium-up) {
      padding-right: 0;
    }
  }

  &.forceRows {
    padding-right: 0;
  }
}

.descriptor {
  display: block;
  margin: 0 0 0.3rem;
  position: absolute;
  right: 0;
  color: $color-text-medium;

  &.respond {
    @media $(bp-medium-up) {
      position: static;
      color: $color-text-dark;
    }
  }

  &.forceRows {
    position: static;
    color: $color-text-dark;
  }
}

.title {
  margin: 0 0 0.3rem;
  font-weight: bold;
  font-size: 1.1rem;
}

.extras {
  color: $color-text-light;
  margin: 0 0 0.3rem;
}

.miniIcons {
  height: 15px;
  width: 15px;
  margin-right: 7px;
  vertical-align: middle;
  margin-top: -3px;
}

.warningMessage {
  font-family: Arial;
  font-size: 10px;
  font-weight: 700;
  line-height: 11.5px;
  text-align: left;
}

.successMessage {
  font-family: Arial;
  font-size: 10px;
  font-weight: 700;
  line-height: 11.5px;
  text-align: left;
  color: #41beae;
}
