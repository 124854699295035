.root {
  transition: 0.3s;
  overflow: hidden;
}

.overflow {
  overflow: visible;
}

.closed {
  max-height: 0;
  visibility: hidden;
  overflow: hidden;
}

.open {
  visibility: visible;
}

.large.open {
  max-height: 50rem;
}

.medium.open {
  max-height: 30rem;
}

.small.open {
  max-height: 10rem;
}
