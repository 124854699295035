@import '../../styles/common/inner-container-full-width';

.root {
  composes: inner-container-full-width;
}

.full {
  max-width: 100%;
}

.addPadding {
  margin: 0 auto;
  padding: 0 2.5rem;
}
