@import '../../../styles/vars/breakpoints';
@import '../../../styles/vars/sizing';
@import '../../../styles/vars/colors';

.bankName {
  margin-left: auto;
  margin-top: $space;
  flex: 1;
}

.row {
  align-items: center;

  @media $(bp-medium-up) {
    display: flex;
    align-items: center;
  }
}

.modalComponent {
  padding: 0;
}

.modal {
  display: grid;
  grid-template-columns: repeat(4, lfr);
  grid-gap: 10px;

  & .modalIcon {
    width: auto;
    height: 180px;
    padding: 24px 31px 24px 31px;
    background-color: $color-action-2;

    & .modalSVG {
      margin-top: auto;
      margin-bottom: auto;
      height: 100%;
    }
  }
  & .modalContent {
    padding: 39px 20px 24px 20px;
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    gap: 16px;

    & .modalHeader {
      color: $color-text-dark;
      font-family: 'Ariel', serif;
      font-weight: 700;
      font-size: 21px;
    }

    & .modalText {
      color: $color-text-medium;
      font-family: 'Arial', serif;
      font-weight: 400;
      font-size: 21px;
    }
  }
  @media $(bp-medium-up) {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 10px;

    & .modalIcon {
      width: 176px;
      height: 100%;
      padding: 24px 31px 24px 31px;
      background-color: $color-action-2;
    }
  }
}
