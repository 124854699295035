@import '../../styles/vars/vars';

.details {
  display: flex;
  margin-bottom: 2em;
}

.detail {
  margin-right: 2em;
}

.cancel {
  margin-left: 2em;
}
