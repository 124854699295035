@import '../../../styles/vars/sizing';
@import '../../../styles/vars/colors';

.root {
  margin-bottom: 4rem;
}

.footer {
  position: fixed;
  bottom: calc($space/2);
  left: calc($space/2);
  right: calc($space/2);
  background: white;
  padding: $space;
  border-top: 1px solid $color-line-light;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}
