@import '../../styles/vars/sizing';
@import '../../styles/vars/colors';
@import '../../styles/common/inner-container';
@import '../../styles/vars/breakpoints';

.root {
  background-size: cover;
  background-position: top center;
  min-height: 100vh;
}

.hasBg {
  @media $(bp-medium-up) {
    background-image: url('../../assets/img/photobg.jpg');
  }
}

.inner {
  composes: inner-container;
  padding: $space;
  margin-top: calc($space * 3);

  @media $(bp-large-up) {
    padding: calc($space * 2) calc($space * 3);
  }
}

.row {
  max-width: 35rem;
  margin: calc($space * 3) auto calc($space * 2);

  @media $(bp-large-up) {
    max-width: none;
    margin: 0;
    display: flex;
  }
}

.col {
  flex: 1;
  margin-bottom: $space;
  padding-bottom: $space;
  border-bottom: 1px solid $color-line-medium;

  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }

  @media $(bp-large-up) {
    border-bottom: 0;
    border-right: 1px solid $color-line-medium;
    padding: 0 calc($space * 3);

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      border-right: 0;
    }
  }
}

.helpCol {
  margin-top: calc($space * 2);

  @media $(bp-large-up) {
    margin-top: 0;
  }
}

.lock {
  display: flex;
  justify-content: center;
  margin-top: -4.5rem;
  margin-bottom: $space;

  & img {
    height: 5rem;
  }

  @media $(bp-large-up) {
    margin-top: -5.5rem;
  }
}
