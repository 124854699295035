@import './vars/colors';
@import './typography';
@import './forms';
@import './table';
@import './helpers';

html {
  box-sizing: border-box;
}

img,
svg {
  width: 100%;
  height: auto;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

::-ms-clear,
::-ms-reveal {
  display: none;
}

:focus {
  outline: $focus-global;
}

:global(#root) {
  outline: none !important;
}

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  color: $color-text-dark;
  background: $color-bg-1;
  outline: none !important;
}

/* White overlay for when user exits */
:global body.hideAll {
  &:before {
    display: block;
    content: '';
    background: white;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 6000;
  }
}

/* Reset list because hardly ever used in terms of copy */
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  cursor: pointer;
}
