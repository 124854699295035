@import '../../styles/vars/vars';

.buttonWrap {
  margin-top: calc($space * 2);
}

.button {
  margin-right: $space;

  @media $(bp-small-only) {
    margin-right: 0;
    width: 100%;
  }
}

.bold {
  display: block;
  margin-bottom: 1em;
}

.boxes {
  display: flex;
  margin: $space calc($space * 2) calc($space * 2);
  justify-content: space-between;
  & > div {
    margin-right: 0;
    width: auto;
  }
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
}

.formWrap {
  display: flex;
  justify-content: center;
  padding-left: 15px;
}

.form {
  text-align: justify;
  max-width: 36rem;
  margin-top: 1em;
  @media $(bp-small-only) {
    max-width: 95%;
  }
}

.asteriskText {
  display: block;
  margin: 1em 0;
  line-height: 130%;
}

.saved {
  color: $color-text-light;
  margin-left: 1em;
  font-size: 0.9em;
}

.bottomText {
  margin-bottom: 2em;
}
