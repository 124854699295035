.pendingTransactions {
  padding-left: 6%;
  padding-right: 6%;
  @media only screen and (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
  @media only screen and (max-width: 600px) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.pendingMark {
  background: #46b6a0;
  color: white;
  float: left;
  font-size: 10px;
  margin-right: 30px;
  margin-top: 3px;
  padding-top: 2px;
  width: 67px;
  height: 15px;
  text-align: center;
  font-weight: 600;
  padding-bottom: 1px;
}
.pendingCardDiv {
  background: #efefef;
  min-height: 40px;
  height: auto;
  display: flex;
  padding: 10px;
  margin-bottom: 1px;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}

.pendingTransName {
  float: left;
  font-size: 14px;
  width: 420px;
  display: block;

  @media only screen and (max-width: 1025px) {
    width: 380px;
  }
  @media only screen and (max-width: 800px) {
    width: 271px;
  }

  @media only screen and (max-width: 600px) {
    width: 110px;
  }
}

.pendingAmountDebit {
  display: inline-block;
  width: 100px;
  text-align: center;
  font-size: 14px;
  @media only screen and (max-width: 770px) {
    display: inline;
    position: absolute;
    right: 240px;
  }
  @media only screen and (max-width: 600px) {
    display: inline;
    position: absolute;
    right: 46px;
  }
}

.pendingAmountCredit {
  display: inline-block;
  width: 100px;
  text-align: center;
  font-size: 14px;
  margin-left: 20px;
  @media only screen and (max-width: 770px) {
    display: inline;
    position: absolute;
    right: 240px;
  }
  @media only screen and (max-width: 600px) {
    display: inline;
    position: absolute;
    right: 46px;
  }
}

.pendingMinus {
  display: none;
  @media only screen and (max-width: 800px) {
    display: inline;
  }
}
.pendingTransactionsHeader {
  padding: 10px;
}
.pendingTransactionHeading1 {
  color: #46b6a0;
  font-size: 12px;
  font-weight: bold;
}
.pendingTransactionHide {
  float: right;
  font-size: 12px;
  color: #bfa77c;
  cursor: pointer;
}
.pendingTransactionShow {
  float: right;
  font-size: 12px;
  color: #bfa77c;
  cursor: pointer;
}

.hideStyle {
  display: inline;
}

.iconClass {
  border: solid #bfa77c;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  vertical-align: middle;
  margin-left: 6px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  vertical-align: top;
  margin-left: 4px;
}
